import styled, { keyframes } from "styled-components";
export const OptionsPage = styled.div`
  height: 100vh;
  .container {
    width: 100%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    /* border-radius: 10px; */
    z-index: -1;
    /* transition: 2s ease-out; */
  }
  button {
    margin-bottom: 2px;
    color: white;
    position: absolute;
  }
  .links {
    ul {
      text-align: left;
      li {
        padding: 1rem;
        color: white;
        font-weight: 400;
        font-size: 32px;
      }
    }
  }

  .nav {
    ul {
      display: flex;
      list-style: none;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      img {
        width: 100%;
      }
      li {
      }
    }
  }
`;
