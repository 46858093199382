import {
  FormControl,
  Grid,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { country_codes } from "../Countries";
import { TalkStyle } from "../style/TalkStyle";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {},
  "& .MuiInputBase-input": {
    borderRadius: 20,
    position: "relative",
    // backgroundColor: theme.palette.background.paper,
    border: " 1px solid #ffffff",
    fontSize: 16,
    margin: "1rem 0",
    padding: "1rem",
    color: "white",
    textAlign: "left",
    // transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 20,
      // borderColor: "#80bdff",
      // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      "label + &": {
        // marginTop: theme.spacing(3),
        color: "white",
      },
    },
  },
}));
const CssTextField = styled(TextField)({
  "& .MuiInputLabel-root": {
    color: "#ffffff",
  },
  "& .MuiInputBase-root": {
    borderRadius: 20,
    color: "#ffffff",
    textAlign: "left",
    // margin: "1rem 0",
    padding: "0.1rem 0",
  },
  "& label.Mui-focused": {
    color: "#ffffff",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
      color: "#ffffff",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
      color: "#ffffff",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
      color: "#ffffff",
    },
  },
});
const TalkWithUs = () => {
  let initalData = {
    name: "",
    phone: "",
    service_id: "",
    email: "",
    code: "",
    message: "",
    company_name: "",
  };
  const [data, setData] = React.useState(initalData);
  const [loading, setLoading] = React.useState(false);

  const { allServices } = useSelector((state) => state?.services);
  let smartyServices = allServices?.filter((e) => e.company_id === 2);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };
  console.log("data", data);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios({
      baseURL: "https://api.adgrouptech.com/api/v1/request-demo",
      method: "POST",
      headers: { "X-Custom-Header": "foobar" },
      data: data,
    })
      .then(() => {
        setLoading(false);
        setData(initalData);
        Swal.fire({
          icon: "success",
          title: "You have requsted a demo",
          showConfirmButton: true,
          timer: 5500,
        });
      })
      .catch((e) => {});
  };
  return (
    <TalkStyle id="contact-us">
      <p className="header">Contact us</p>
      {/* <p className="lorem">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo.Lorem ipsum dolor m, q
      </p> */}
      <div className="form">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {" "}
              <CssTextField
                label="Name"
                onChange={handleChange}
                name="name"
                value={data?.name}
                fullWidth
              ></CssTextField>
            </Grid>
            <Grid item xs={12}>
              <CssTextField
                label="E_mail"
                onChange={handleChange}
                name="email"
                value={data?.email}
                fullWidth
              ></CssTextField>
            </Grid>
            <Grid item xs={12}>
              <div className="countryCode">
                <CssTextField
                  select
                  id="demo-simple-select"
                  required
                  label="Country Code"
                  onChange={handleChange}
                  defaultValue=""
                  name="code"
                  value={data?.code}
                  sx={{ width: "50%" }}
                >
                  {country_codes?.map((data, index) => (
                    <MenuItem key={index} value={data.code}>
                      {data.name} {data?.code}
                    </MenuItem>
                  ))}
                </CssTextField>
                <CssTextField
                  label="Phone"
                  placeholder="Phone"
                  id="outlined-start-adornment"
                  fullWidth
                  onChange={handleChange}
                  name="phone"
                  value={data?.phone}
                />
                <CssTextField
                  label="Company Name"
                  placeholder="company_name"
                  id="outlined-start-adornment"
                  fullWidth
                  onChange={handleChange}
                  name="company_name"
                  value={data?.company_name}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <CssTextField
                id="outlined-select-currency"
                select
                label="Services"
                defaultValue="EUR"
                helperText="Please select your currency"
                fullWidth
                name="service_id"
                onChange={handleChange}
                value={data?.service_id}
              >
                {smartyServices.map((option) => (
                  <MenuItem key={option.value} value={option.id}>
                    {option.title}
                  </MenuItem>
                ))}
              </CssTextField>
            </Grid>
            <Grid item xs={12}>
              <textarea
                placeholder="Message"
                rows="4"
                cols="50"
                onChange={handleChange}
                name="message"
                value={data?.message}
              ></textarea>
            </Grid>
            <Grid item xs={12}>
              <button>GET IN TOUCH {loading ? "sending..." : ""}</button>
            </Grid>
          </Grid>
        </form>
      </div>
    </TalkStyle>
  );
};

export default TalkWithUs;
