import React from "react";
import styled from "styled-components";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = React.useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <Bigsection>
      <p className="text">
        {isReadMore ? text?.slice(0, 150) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "...read more" : " show less"}
        </span>
      </p>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  .text {
    display: inline;
    width: 100%;
    font-size: 14px;
  }

  .read-or-hide {
    color: rgb(192, 192, 192);
    cursor: pointer;
  }
`;
export default React.memo(ReadMore);
