import { Button } from "@mui/material";
import React from "react";
import styled from "styled-components";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// const BackButton = () => {
//   return (
//     <BackButtonStyle endIcon={<KeyboardBackspaceIcon></KeyboardBackspaceIcon>}>
//       Back
//     </BackButtonStyle>
//   );
// };
export const BackButtonStyle = styled(Button)`
  && {
    text-align: left;
    margin-top: 2rem;
    margin-left: 3rem;
    color: white;
    letter-spacing: 2px;
    font-size: 18px;
    border: 1px solid white;
    padding: 0.2rem 2rem;
  }
`;

// export default BackButton;
