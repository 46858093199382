import React from "react";
import { NavStyle, StyledLink } from "../style/NavBarStyle";
import logo from "../assets/rsz_logo-f.webp";
import { Button, Grid } from "@mui/material";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Options from "../pages/Options";
const NavBar = () => {
  return (
    <Grid container columns={12}>
      <Grid item xs={12}>
        {" "}
        <NavStyle>
          <div>
            <ul>
              <StyledLink to="/">
                <li>
                  <img src={logo} alt="" />
                </li>
              </StyledLink>
            </ul>
          </div>
          <div className="hide">
            <ul>
              <StyledLink to="#about" smooth>
                {" "}
                <li>About</li>
              </StyledLink>
              <StyledLink to="#why" smooth>
                {" "}
                <li>Why Smarty</li>
              </StyledLink>
              <StyledLink to="#services" smooth>
                {" "}
                <li>Services</li>
              </StyledLink>
              {/* <StyledLink to="/succes-stories" smooth>
                {" "}
                <li>Success Stories</li>
              </StyledLink> */}
              {/* <StyledLink to="#reviews" smooth>
                {" "}
                <li>What People Say</li>
              </StyledLink> */}
              <StyledLink to="#questions" smooth>
                {" "}
                <li>FQA</li>
              </StyledLink>

              <StyledLink to="#contact-us" smooth>
                {" "}
                <li>Contact us</li>
              </StyledLink>
            </ul>
          </div>
        </NavStyle>
      </Grid>
    </Grid>
  );
};

export default NavBar;
