import { Grid } from "@mui/material";
import React from "react";
import { ServiceStyle } from "../style/OurServiceStyle";
// import service1 from "../assets/services/service wz size-01.png";
// import service2 from "../assets/services/servicesTwo.png";
// import service3 from "../assets/services/servicesThree.png";
// import service4 from "../assets/services/servicesFour.png";
import { StyledLink } from "../style/NavBarStyle";
// import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import ImageLazyLoading from "./ImageLazyLoading";
// import { Roll, Rotate } from "react-awesome-reveal";

const OurServices = () => {
  const { allServices } = useSelector((state) => state?.services);
  let smartyServices = allServices?.filter((e) => e.company_id === 2);
  return (
    <ServiceStyle id="services">
      <div>
        {" "}
        <p className="header">Our Services</p>
        <p className="lorem">
          With the rise of technology, ‎having a comprehensive and effective
          ‎digital education strategy is essential for ‎any educational
          institution or ‎organization. That's why at smarty we ‎offer a broad
          range of Digital Education ‎Solutions that are designed to fully meet
          ‎various educational needs.
        </p>
      </div>
      <Grid
        container
        columns={12}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {smartyServices?.length > 0 ? (
          <>
            {" "}
            {smartyServices?.map((e, i) => {
              return (
                <Grid item xs={12} lg={4} md={6} key={i}>
                  <div className="singleDiv">
                    <StyledLink to={`/services/${e?.id}/about`}>
                      {/* <ImageLazyLoading
                        image={e?.thumbnail_path}
                      ></ImageLazyLoading> */}
                      <img
                        src={e?.thumbnail_path}
                        onLoad={() => console.log("images loaded")}
                      ></img>
                      <p>{e?.title}</p>
                    </StyledLink>
                  </div>
                </Grid>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </Grid>
      {/* <div className="flexcontainer">
        {arr?.map((e, i) => {
          return (
            <div className="singleDiv">
              <StyledLink to="/services/5/about">
                <img src={service3}></img>
              </StyledLink>
            </div>
          );
        })}
      </div> */}
      {/* <div>
        <button className="learn">Learn More</button>
      </div> */}
    </ServiceStyle>
  );
};

export default React.memo(OurServices);
