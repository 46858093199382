import styled from "styled-components";

export const Bigsection = styled.div`
  height: 100%;
  .container {
    text-align: center;
    //   border-radius: 15px;
    //   background-color: red;
  }
  .post-image {
    background: #fff;
    img {
      border-radius: 10px 10px 0 0;
      background: #fff;
      // width: 100%;
      // max-width: 100%;
      // height: 100%;
      width: 100%;
      height: 300px;
      object-fit: cover;
      padding: 0;
      margin: 0;
      outline: 0;
    }
    padding: 0 !important;
    margin: 0 !important;
  }
  .post-image .category a {
    background: #9c8156;
    border-radius: 5px;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 12px;
    left: 25px;
    line-height: 1;
    padding: 8px 24px;
    position: absolute;
    text-transform: uppercase;
    top: 25px;
  }
  .post-text {
    background: #fff;
    padding: 0;
    margin: 0;
  }
  .post-text .date {
    color: #9c8156;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
  }
  .post-text h2 {
    margin-bottom: 0;
    font-size: 24px;
    line-height: 1.3;
  }
  .post-info {
    background: #fff;
    padding: 18px 50px;
    margin-top: 2px;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .post-by {
    color: #858585;
    float: left;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 1;
  }
  .post-by a {
    text-transform: uppercase;
    color: #9c8156;
  }
`;
