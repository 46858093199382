import React from "react";
import { BigSection } from "./style";
const SingleBlogData = ({ id, data }) => {
  const singleBlog = data;
  const paragraphArray = singleBlog?.blog_descriptions;
  return (
    <BigSection>
      <div className="singleBlogDataContainer">
        <div className="content">
          <div className="date">
            <img src={singleBlog?.media?.file_path} alt="blog"></img>
            {/* <h1>September 21, 2022</h1> */}
            <h2>{singleBlog?.title}</h2>
          </div>
          <div>
            {paragraphArray?.map((pargraph) => {
              return (
                <div className="singleParagraph" key={pargraph?.id}>
                  <h3>{pargraph?.title} : </h3>
                  <p>
                    {pargraph?.body?.includes("\n") ? (
                      <>
                        {pargraph?.body?.split("\n")?.map((list, index) => {
                          return (
                            <ul key={index}>
                              <li>{list}</li>
                            </ul>
                          );
                        })}
                      </>
                    ) : (
                      <>{pargraph?.body}</>
                    )}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </BigSection>
  );
};

export default SingleBlogData;
