import React from "react";
import styled from "styled-components";
import ReadMore from "../ReadMore";

const CibCardSlider = ({ card }) => {
  return (
    <BigSection>
      <div>
        {" "}
        <img src={card.icon}></img>
      </div>
      <div>
        {" "}
        <h4>{card.header}</h4>
      </div>
      <div>
        {" "}
        <p>
          {" "}
          <ReadMore>{card.description}</ReadMore>
        </p>
      </div>
      {/* <div className="btnContainer">
    {" "}
    <button className="btn">LearnMore</button>
  </div> */}

      {/* </div> */}
    </BigSection>
  );
};
const BigSection = styled.div`
  border: 2px solid hsla(0, 0%, 100%, 0.5);
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 20%);
  padding: 30px 0;
  text-align: center;
  color: #fff;
  fill: #fff;
  position: relative;
  transition: all 0.3s;
  min-height: 350.6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  p {
    /* max-width: 50%; */
    /* margin: auto; */
  }

  img {
    width: 60px;
    height: 60px;
  }
`;
export default CibCardSlider;
