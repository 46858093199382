import styled from "styled-components";

export const FooterStyle = styled.div`
  background-color: #121212;
  color: #cecece;
  padding: 3rem 2rem;
  ul {
    list-style: none;
    li {
      color: white;
      overflow: hidden;
      padding: 0.5rem 0;
      svg {
        margin-right: 15px;
        font-size: 36px;
        transition: 0.3s ease-in;
        &:hover {
          transform: scale(1.2);
          cursor: pointer;
        }
      }
      img {
        width: 50%;
      }
      p {
        width: 90%;
      }
      a {
        text-decoration: none;
        color: white;
      }
    }
  }
  .firstUl {
    /* padding-top: 30px; */
  }
  .padding {
    li {
      padding: 1rem 0;
    }
  }
  .location {
    width: 100%;
  }
  h3 {
    background: transparent
      linear-gradient(98deg, #2b2b2b 0%, #ffffff 0%, #4288ec 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 22px;
    font-weight: bold;
    background-clip: text;
    -webkit-background-clip: text;
  }
  .imageFilter {
    max-width: 11%;
    filter: grayscale(100%);
    transition: all 0.3s;
    margin-right: 15px;
    &:hover {
      filter: none;
      scale: 1.1;
    }
  }
`;
