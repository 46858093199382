import styled from "styled-components";
import bg from "../assets/TalkwithUs.webp";

export const Input = styled.input`
  width: 100%;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 20px;
  border: 1px solid #ffffff;
  background-color: transparent;
  color: white;
`;
export const TalkStyle = styled.div`
  text-align: center;
  background-image: url(${bg});
  min-height: 100vh;
  padding: 3rem 0;
  color: white;
  background-position: right;
  background-position: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  .header {
    background: transparent
      linear-gradient(98deg, #2b2b2b 0%, #ffffff 0%, #4288ec 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
    font-weight: bold;
    background-clip: text;
    -webkit-background-clip: text;
  }
  .lorem {
    width: 50%;
    margin: auto;
    padding: 2rem 0;
  }
  .form {
    width: 50%;
    margin: auto;
    @media (max-width: 760px) {
      width: 100%;
    }
  }

  input {
    /* width: 100%;
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 20px;
    border: 1px solid #ffffff;
    background-color: transparent;
    color: white; */
  }
  textarea {
    width: 100%;
    /* margin: 1rem 0; */
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ffffff;
    background-color: transparent;
    color: white;
    height: 150px;
  }
  button {
    color: black;
    background-color: white;
    border-radius: 37px;
    padding: 1rem 4rem;
    font-size: 16px;
    cursor: pointer;
  }
  .countryCode {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 20px;
  }
  .css-rrdfqm-MuiFormLabel-root-MuiInputLabel-root,
  svg {
    color: white !important;
  }
  @media (max-width: 800px) {
    .countryCode {
      flex-direction: column;
    }
  }
`;
