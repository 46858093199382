import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { StyledLink } from "../../style/NavBarStyle";
import { useLocation } from "react-router-dom";
const Content = () => {
  const { singleService } = useSelector((state) => state?.services);
  const location = useLocation();
  const idService = location.pathname.split("/")[2];
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Bisgsection>
      <Grid container columns={12} pt={35} spacing={1}>
        {singleService?.catalogues?.map((e) => {
          return (
            <Grid item xs={12} md={6} lg={3} key={e?.id}>
              <div className="singlediv">
                <div className="content">
                  <StyledLink
                    to={`/${idService}/movie/${e?.id}`}
                    target="_blank"
                  >
                    <img src={e?.thumbnail_path}></img>
                    <p className="name">{e?.title}</p>
                  </StyledLink>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Bisgsection>
  );
};

const Bisgsection = styled.div`
  padding: 0 4rem;
  padding-bottom: 15rem;
  .header {
    text-align: left !important;
    text-transform: uppercase;
    background: transparent linear-gradient(100deg, #ffffff 0%, #377ceb 100%) 0%
      0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 25px;
    font-weight: bold;
    width: 340px;
  }
  .singlediv {
    padding-bottom: 1rem;
    img {
      width: 260px;
      height: 384px;
      object-fit: contain;
    }
    .content {
      width: 100%;
      text-align: center;
      .name {
        font-size: 20px;
        font-weight: 500;
      }
    }
    a {
      color: inherit;
    }
  }
`;

export default Content;
