import React, { useLayoutEffect, useState } from "react";
import { Grid } from "@mui/material";
import { BigSection } from "./style";
import axios from "axios";
import { Link } from "react-router-dom";
import NewSignleBlog from "../../../blogs/components/newSingleBlog";
const OtherBlogs = () => {
  // const data = await fetchBlogPage();
  // const res = await data;
  // const allBlogs = res?.data?.data;
  const [allBlogs, setAllBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async () => {
    setIsLoading(true);
    axios
      .get("https://octarback.adgrouptech.com/api/blogs")
      .then(function (response) {
        // handle success
        setIsLoading(false);
        setAllBlogs(response?.data?.data);
        console.log("response?.data?.data", response?.data?.data?.data);
      })
      .catch(function (error) {
        setIsLoading(false);

        // handle error
        console.log(error);
      });
  };
  console.log("allBlogs", allBlogs?.data);
  useLayoutEffect(() => {
    fetchData();
  }, []);
  return (
    <BigSection>
      <div className="otherBlogsContainer">
        <Grid
          container
          columns={12}
          flexShrink={0}
          alignItems={"flex-start"}
          spacing={1}
        >
          <h3>Other articles by the author</h3>
          {/* {blogsArr?.map((blog) => {
          return (
            <Grid item xs={12} md={12} lg={12} sm={6} key={blog?.id}>
              <Link
                href={{
                  pathname: "/home/blog/singleBlog",
                  query: { id: `${blog?.id}` },
                }}
              >
                <NewSignleBlog data={blog} />
              </Link>
            </Grid>
          );
        })} */}
          {allBlogs?.data?.map((blog) => {
            return (
              <Grid item xs={12} md={12} lg={12} sm={6} key={blog?.id}>
                <Link to={`/blogs/singleBlog/${blog?.id}`}>
                  <NewSignleBlog data={blog} />
                </Link>
              </Grid>
            );
          })}
          {/* <Grid item xs={12} md={12} lg={12} sm={6}>
          {" "}
          <div className=singleDiv}>
            <h1>
              {` Press: Osso VR Unveils its "Any Specialty, Anytime, Anywhere"
              Campaign, Showcasing Comprehensive Surgical Training Across
              Specialties`}
            </h1>
            <div className=photo}>
              <div></div>
              <p>Stephanie Fraser</p>
            </div>
            <p className=date}>September 21, 2022</p>
          </div>
        </Grid> */}
        </Grid>
      </div>
    </BigSection>
  );
};

export default OtherBlogs;
