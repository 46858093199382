import { Grid } from "@mui/material";
import React from "react";
import { WhyStyle } from "../style/whySmartyStyle";
import ReadMore from "./ReadMore";

import { useSelector } from "react-redux";
import CibCard from "./Layout/CibCard";
import { Fade } from "react-awesome-reveal";
import { Slide } from "react-awesome-reveal";
import { arr } from "../StaticArrays";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { EffectCube, Pagination, Navigation } from "swiper";
import WhysmartySlider from "./WhysmartySlider";

const WhySmarty = () => {
  const { allPages, singlePage } = useSelector((state) => state?.pages);
  const smartPages = allPages?.filter((e) => e.company_id === 2);
  const whySection = smartPages?.filter((e) => e?.section === "why");
  const LastIndex = whySection[whySection?.length - 1];
  return (
    <WhyStyle id="why">
      <Grid container columns={12}>
        <Grid item xs={12}>
          <div>
            <Fade>
              <p className="header">{LastIndex?.title}</p>
            </Fade>
            <p className="lorem">{LastIndex?.description}</p>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        columns={12}
        style={{ padding: "3rem 5rem", textAlign: "center" }}
        className="normalSlider"
        // spacing={2}
      >
        {[...new Set(arr)]?.map((e, i) => {
          return (
            <Grid item xs={12} md={6} lg={3} key={i}>
              <Slide duration={500} triggerOnce={true} direction={e.direction}>
                <CibCard card={e}></CibCard>
              </Slide>
            </Grid>
          );
        })}
      </Grid>
      <Grid container columns={12}>
        <Grid item xs={12} className="sliderContainer">
          <WhysmartySlider arr={arr} />
        </Grid>
      </Grid>
    </WhyStyle>
  );
};

export default WhySmarty;
