import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { AboutSmartyStyle } from "../style/AboutSmartyStyle";
import { JackInTheBox } from "react-awesome-reveal";
import "animate.css";

const AboutSmarty = () => {
  const { allPages, singlePage } = useSelector((state) => state?.pages);
  const smartPages = allPages?.filter((e) => e.company_id === 2);
  const aboutSection = smartPages?.filter((e) => e?.section === "about");
  const LastIndex = aboutSection[aboutSection?.length - 1];
  return (
    <AboutSmartyStyle id="about">
      <Grid container columns={12}>
        <Grid item xs={12}>
          <div className="hero">
            {" "}
            <div>
              {" "}
              <p className="header">{LastIndex?.title}</p>
            </div>
            {/* <JackInTheBox triggerOnce> */}
            <div>
              {" "}
              <p className="lorem">{LastIndex?.description}</p>
            </div>
            {/* </JackInTheBox> */}
            {/* <div>  <button className="learn">Get more info</button> </div> */}
          </div>
        </Grid>
      </Grid>
    </AboutSmartyStyle>
  );
};

export default AboutSmarty;
