import styled from "styled-components";
import background from "../assets/reviewBg.webp";

export const ReviewStyle = styled.div`
  background-image: url(${background});
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  color: white;
  background-attachment: fixed;
  background-color: black;
  padding: 5rem 0;
  .hero {
    width: 50%;
    margin: auto;
    text-align: left;
    .header {
      background: transparent
        linear-gradient(98deg, #2b2b2b 0%, #ffffff 0%, #4288ec 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 50px;
      font-weight: bold;
      background-clip: text;
      -webkit-background-clip: text;
    }
    .descrp {
      color: white;
      width: 70%;
    }
  }
  .singlephoto {
    position: relative;
    img {
      width: 100%;
    }
    &:after {
      content: "";
      transition: 1.3s all;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      display: none;
    }
    &:hover {
      transition: 1.3s all;

      &:after {
        display: block;
      }
      .overlay {
        visibility: visible;
      }
    }
  }
  .overlay {
    position: absolute;
    width: 100%;
    bottom: 20px;
    display: flex;
    left: 5px;
    justify-content: space-around;
    visibility: hidden;
    transition: 0.3s all;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border: 2px solid white;
      background-color: #3e3f40;
      opacity: 0.8;
      padding: 0.3em 0.4rem;
      border-radius: 8px;
      cursor: pointer;
    }
    .name {
      font-size: 18px;
      font-weight: 400;
    }
    .job {
      font-size: 13px;
      /* font-weight: 400; */
    }
  }
`;
