export const questions = [
  {
    question: "What is VR and AR technology ",
    answer:
      "VR (virtual reality) and AR (augmented reality) are immersive technologies that allow users to experience digital content in a 3D environment. VR technology creates a completely immersive environment, while AR technology overlays digital content onto the real world",
  },
  {
    question: "How can VR and AR technology be used in the classroom ",
    answer:
      "VR and AR technology can be used to create immersive and engaging learning experiences, allowing students to explore subjects in a new and interactive way. It can also help improve student engagement and learning outcomes.",
  },
  {
    question:
      "What are the benefits of using VR and AR technology in the classroom ",
    answer:
      `The benefits of using VR and AR technology in the classroom include improved student engagement, increased retention of information, you can also check more reasons`,
  },
  {
    question:
      "How can your company help me implement VR and AR technology in my classroom ",
    answer:
      " Our company specializes in transferring normal school curriculum into metaverse content. We provide customized solutions tailored to the needs and challenges of your school and offer ongoing support and resources to ensure successful implementation",
  },
  {
    question:
      "How can VR and AR technology help create a collaborative learning environment ",
    answer:
      "VR and AR technology can help create a collaborative learning environment by allowing students to connect with other schools and educational institutions. It can also facilitate group projects and peer-to-peer learning",
  },
  // {
  //   question: "How can I convince my school to invest in VR and AR technology ",
  //   answer:
  //     "Our company provides case studies and testimonials from other educators who have successfully implemented VR and AR technology in their classrooms, as well as customized proposals that outline how our technology can specifically address the needs and challenges of your school",
  // },
  {
    question:
      "How can I ensure successful implementation of VR and AR technology in my school ",
    answer:
      "Our company offers ongoing support and guidance, as well as training and resources for educators and IT staff.",
  },
  {
    question: "What kind of support do you offer after implementation ",
    answer:
      "Our company offers ongoing support and resources, including online tutorials, virtual Q&A sessions, and access to a dedicated support team.",
  },
  {
    question: "What kind of subjects can I explore using VR and AR technology ",
    answer:
      "VR and AR technology can be used to explore a wide range of subjects, including Science, Math, History, Geography and more.",
  },
  {
    question: "How does VR and AR technology make learning more engaging ",
    answer:
      "VR and AR technology creates an immersive and interactive learning experience that can help improve student engagement and retention of information",
  },
  {
    question: " Can I use VR and AR technology at home ",
    answer:
      "One of our platform’s mean features is that student can join the classroom anywhere even from his home",
  },
  {
    question:
      " How can I get started with using VR and AR technology in my classroom ",
    answer:
      "Our company provides a range of resources and support to help you get started, including online tutorials, webinars, and customized solutions tailored to your needs and challenges.",
  },
];
