import React from 'react'
import Aboutsection from '../components/AboutPage/Aboutsection'
import NeedMore from '../components/AboutPage/NeedMore'
import ThreeDivs from '../components/AboutPage/ThreeDivs'
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'

const About = () => {
  return (
    <div>
        <NavBar></NavBar>
        <Aboutsection></Aboutsection>
        <ThreeDivs></ThreeDivs>
        <NeedMore></NeedMore>
        <Footer></Footer>
    </div>
  )
}

export default About