import styled from "styled-components";

export const Bigsection = styled.div`
  background-color: #000;
  color: white;
  padding-top: 6rem;

  .dynamicBlogContainer {
    margin: 0rem 3rem;
    //   min-height: 100vh;
    .nav {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: white;
      p {
        color: white;
        /* Body */
        font-family: Open Sans;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 180%; /* 2.025rem */
      }
      svg {
        filter: invert(100%) sepia(22%) saturate(0%) hue-rotate(174deg)
          brightness(112%) contrast(100%);
      }
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }
`;
