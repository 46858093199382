import React from "react";
import styled from "styled-components";
import lottie from "../assets/json/SMARTY LOGOoOo.mp4.lottie.json";
import LottieComponent from "./LottieComponent";

const Loader = () => {
  return (
    <Div>
      <div className="loader">
        <LottieComponent lottie={lottie}></LottieComponent>
        {/* Loading ... */}
      </div>
    </Div>
  );
};
const Div = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  color: white;
  .loader {
    width: 300px;
    height: 300px;
    /* font-size: 1.5rem; */
  }
`;
export default Loader;
