import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif !important;
    
}
body{
    font-family: 'Roboto', sans-serif !important;
    overflow-x:hidden !important;
    
}
.css-qiwgdb.css-qiwgdb.css-qiwgdb{
    color: white !important;
}
a{
    text-decoration: none;
}
`;
