import React from "react";
import astrials from "../assets/Astrials.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NavBar from "../components/NavBar";
import styled from "styled-components";
import BG from "../assets/UnderWaterBG.webp";
import { Button, Grid } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Footer from "../components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {
  Parallax,
  Pagination,
  Navigation,
  EffectCoverflow,
  Autoplay,
} from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { GetSingleCatalogue } from "../Redux/Slices/Catalogue/Catalogue";
import Loader from "../components/Loader";
import { GetSingleService } from "../Redux/Slices/ServicesSlice/ServicesSlice";
import { StyledLink } from "../style/NavBarStyle";
import { BackButtonStyle } from "../components/Layout/BackButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  lazyLoad: true,
};
const pagination = {
  clickable: true,
  renderBullet: function (index, className) {
    return '<span class="' + className + '">' + "</span>";
  },
};
const Astrials = () => {
  const [color, setColor] = React.useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const serviceId = location.pathname.split("/")[1];
  console.log("serviceId", serviceId);
  React.useEffect(() => {
    dispatch(GetSingleCatalogue(id));
    dispatch(GetSingleService(serviceId));
  }, [id]);
  const { singleCatalogue, catalogueStatus } = useSelector(
    (state) => state.catalogue
  );
  const { singleService, servicesStatus, allServices } = useSelector(
    (state) => state?.services
  );

  React.useEffect(() => {
    if (id === "15" || id === "23" || id === "29") {
      setColor("black");
    }
    if (
      id === "14" ||
      id === "16" ||
      id === "17" ||
      id === "18" ||
      id === "19" ||
      id === "21" ||
      id === "22" ||
      id === "24" ||
      id === "25" ||
      id === "27" ||
      id === "28" ||
      id === "31" ||
      id === "32" ||
      id === "33" ||
      id === "35" ||
      id === "36" ||
      id === "38" ||
      id === "39" ||
      id === "40"
    ) {
      setColor("white");
    }
  }, [id]);
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/#services");
  };
  console.log("singleCatalogue?.video_url", singleCatalogue?.video_url);
  console.log(
    "singleCatalogue?.video_url editt",
    singleCatalogue?.video_url.replace("embed/", "watch?v=")
  );
  return (
    <>
      {catalogueStatus === "loading" ? (
        <>
          <Loader></Loader>
        </>
      ) : (
        <>
          {" "}
          <BigSection
            bg={`https://api.adgrouptech.com/images/services/${singleCatalogue?.background_path}`}
            color={color}
          >
            {/* <NavBar></NavBar> */}

            <div className="hero">
              <div className="content">
                <Grid
                  container
                  columns={12}
                  className="bbb"
                  style={{ padding: "3rem 2rem" }}
                >
                  <div className="overlay"></div>
                  <Grid item xs={12} className="flex">
                    {/* <Button
                      endIcon={<KeyboardBackspaceIcon></KeyboardBackspaceIcon>}
                      onClick={() => {
                        goBack();
                      }}
                    >
                      Back
                    </Button> */}
                    <img
                      src={`https://api.adgrouptech.com/images/services/${singleCatalogue?.logo_path}`}
                    ></img>
                    <div className="desc">
                      <p className="header">{singleCatalogue?.title}</p>
                      <p className="year">(2014)</p>
                    </div>
                    <div className="desc">
                      <p>The story of the supernova</p>
                      <p>
                        {singleCatalogue?.age}+ | {singleCatalogue?.duration}{" "}
                        min. | VR / Fulldome
                      </p>
                    </div>
                    <div className="desc">
                      <p>
                        {" "}
                        This bright and colorful film tells the story of a
                        star’s life cycle:
                      </p>
                      <p>
                        from its birth in gas dust nebulae to the end of life in
                        the form of a black hole or a supernova star. All these
                        phenomena are explained to the maximum extent possible
                        and are accessible for both children and students.
                      </p>
                      {singleCatalogue?.video_url ? (
                        <>
                          {" "}
                          <a
                            href={`${singleCatalogue?.video_url.replace(
                              "embed/",
                              "watch?v="
                            )}`}
                            target="_blank"
                          >
                            <MyButton
                              endIcon={<KeyboardArrowRightIcon />}
                              className="btn"
                            >
                              Watch a trailer
                            </MyButton>
                          </a>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <div className="backround-color">
                  <Grid container columns={12}>
                    <Grid item xs={12} md={6} className="border">
                      <div className="singlediv ">
                        <div className="content ">
                          <h2>Main information</h2>
                          <p>Studio: Aayushi Fulldome Films</p>
                          <p>Genre: Astronomy</p>
                          <p>Subject: Star’s life cycle</p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="singlediv">
                        <div className="content">
                          <h2>Languages</h2>
                          <p>{singleCatalogue?.language}</p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>

                {/* <Grid container columns={12} className="slide">
                  <Grid item xs={12}>
                    <Slider {...settings}>
                      <div>
                        <img src={img1}></img>
                      </div>
                      <div>
                        <img src={img2}></img>
                      </div>
                      <div>
                        <img src={img3}></img>
                      </div>
                      <div>
                        <img src={img1}></img>
                      </div>
                      <div>
                        <img src={img2}></img>
                      </div>
                      <div>
                        <img src={img3}></img>
                      </div>
                    </Slider>
                  </Grid>
                </Grid> */}
                <Grid container columns={12}>
                  <Grid item xs={12}>
                    <p className="head">Similar shows</p>
                  </Grid>
                  <Grid item xs={12}>
                    <Swiper
                      effect={"coverflow"}
                      grabCursor={true}
                      centeredSlides={true}
                      slidesPerView={"auto"}
                      coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                      }}
                      autoplay={{
                        delay: 2000,
                        disableOnInteraction: true,
                      }}
                      pagination={true}
                      modules={[EffectCoverflow, Pagination, Autoplay]}
                      className="mySwiper"
                    >
                      {singleService?.catalogues?.map((e, i) => {
                        return (
                          <SwiperSlide>
                            <StyledLink to={`/${serviceId}/movie/${e?.id}`}>
                              <img src={e?.thumbnail_path}></img>
                            </StyledLink>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>{" "}
                  </Grid>
                </Grid>
              </div>
            </div>
          </BigSection>
        </>
      )}

      <Footer></Footer>
    </>
  );
};
const BigSection = styled.div`
  .content {
  }
  .hero {
    position: relative;
    img {
      width: 18%;
    }
    .bbb {
      /* background-image: url(${BG}); */
      background-image: url(${(props) => props.bg});
      min-height: 100vh;
      background-attachment: fixed;
      background-position: top;
      background-repeat: no-repeat;
      /* background-origin: content-box; */
      background-size: cover;
      text-align: left;
      background-color: #000;
      overflow: hidden;
      color: ${(props) => props.color};
      /* background-size: 100vw; */
      background-size: cover;

      position: relative;
      .overlay {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: -webkit-linear-gradient(
          top,
          rgba(250, 250, 250, 0),
          rgba(250, 250, 250, 1)
        );
        height: 100vh;
        /* background-image: linear-gradient(
          top,
          rgba(250, 250, 250, 0),
          rgba(250, 250, 250, 0.9)
        ); */
        /* filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#fefafafa', endColorstr='#00fafafa'); */
      }
    }
    .flex {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: 3;
    }
    .backround-color {
      /* padding-top: 45px; */
      /* padding-bottom: 75px; */
      background-color: #fafafa;
      opacity: 0.9;
    }
    .desc p {
      line-height: 35px;
      width: 45%;
    }
    .year {
      line-height: 10px;
    }
    .desc {
      /* padding: 0.7rem 0; */
    }
    .header {
      font-weight: 800;
      font-size: 20px;
    }
  }
  .head {
    padding-top: 4rem;
    font-size: 28px;
    font-weight: 400;
    /* padding-left: 3rem; */
    text-align: center;
    width: 100%;
    max-width: 100%;
  }
  .singlediv {
    /* padding: 1rem; */
    /* text-align: center; */
    h2,
    p {
      max-width: 100%;
    }
    p {
      opacity: 0.71;
      line-height: unset;
      padding-bottom: 0.7rem;
    }
    .content {
      width: 50%;
      margin: 0 auto;
    }
  }
  .border {
    /* border-right: 1px solid #707070; */
    /* background-clip: padding-box; */
  }
  .slide {
    img {
      width: 100%;
    }
  }
  .singlediv {
    img {
      width: 50%;
    }
    .content {
      width: 75%;
      text-align: center;
      .name {
        font-size: 20px;
        font-weight: 500;
      }
    }
    a {
      color: inherit;
    }
  }
  .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 15%;
    height: auto;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
  }
  @media (max-width: 760px) {
    .desc p {
      width: 100% !important;
    }
  }
`;
const MyButton = styled(Button)`
  && {
    border: 1px solid #ffffff;
    border-radius: 46px;
    background-color: black;
    color: white;
    padding: 1rem 3rem;
    font-size: 14px;
    margin: 2rem 0;
  }
`;
export default Astrials;
