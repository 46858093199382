import React from "react";
import { Bigsection } from "./style";

const NewSignleBlog = ({ data }) => {
  return (
    <Bigsection>
      <article className="container">
        <div className="post-image">
          <img src={data?.media?.file_path} alt="post image 1" />
        </div>
        <div className="post-text">
          <span className=".date">
            {new Date(data?.created_at)?.toLocaleDateString("en-GB", {
              timeZone: "UTC",
            })}
          </span>
          <h2>{data?.title}</h2>
        </div>
        <div className="post-info">
          <div className="post-by">
            Post By <a href="#">{data?.author}</a>
          </div>
        </div>
      </article>
    </Bigsection>
  );
};

export default NewSignleBlog;
