import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "80vh",
  width: 800,
  overflow: "scroll",
};

const PrivacyAndPolicy = ({ open, handleClose }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            Privacy and Policy{" "}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <h3> Introduction</h3>
            <p style={{ padding: "1rem 0" }}>
              This privacy policy outlines how our website collects, uses, and
              protects any information that you provide while using our website.
              We are committed to ensuring that your privacy is protected.
              Should we ask you to provide certain information by which you can
              be identified when using this website, then you can be assured
              that it will only be used in accordance with this privacy
              statement.
            </p>
            <h3>What information we collect</h3>
            <p style={{ padding: "1rem 0" }}>
              We may collect the following information:
            </p>
            <ul>
              <li>
                Personal information, such as your name, email address, and
                other contact details that you provide when filling out a form
                on our website.
              </li>{" "}
              <li>
                Use information, such as the pages you visit, the links you
                click, and other actions you take when using our website.
              </li>
              <li>
                Device and browser information, such as your IP address, browser
                type, operating system, and other technical details that may be
                collected automatically by our website.
              </li>
            </ul>
            <h3>How we use your information</h3>
            <p style={{ padding: "1rem 0" }}>
              We use the information we collect for the following purposes:
            </p>{" "}
            <ul>
              <li>
                To provide you with the products, services, or information that
                you request from us.
              </li>{" "}
              <li>
                To improve our website and the products and services we offer
              </li>
              <li>
                To send you promotional emails about our products, special
                offers, or other information that we think you may find
                interesting.
              </li>
              <li>
                To respond to your inquiries, comments, or other requests.
              </li>
            </ul>
            <p style={{ padding: "1rem 0" }}>
              We may also use your information for any other purpose that we
              disclose to you at the time we collect the information or that you
              consent to.
            </p>
            <h3>How we protect your information</h3>
            <p style={{ padding: "1rem 0" }}>
              We are committed to ensuring that your information is secure. In
              order to prevent unauthorized access or disclosure, we have put in
              place suitable physical, electronic, and managerial procedures to
              safeguard and secure the information we collect online.
            </p>
            <h3>How we share your information</h3>
            <p style={{ padding: "1rem 0" }}>
              We do not sell, trade, or otherwise transfer your personally
              identifiable information to outside parties. This does not include
              trusted third parties who assist us in operating our website,
              conducting our business, or servicing you, as long as those
              parties agree to keep this information confidential. We may also
              release your information when we believe release is appropriate to
              comply with the law, enforce our site policies, or protect ours or
              others' rights, property, or safety.
            </p>
            <h3>Links to other websites</h3>
            <p style={{ padding: "1rem 0" }}>
              Our website may contain links to other websites of interest.
              However, once you have used these links to leave our site, you
              should note that we do not have any control over that other
              website. Therefore, we cannot be responsible for the protection
              and privacy of any information that you provide while visiting
              such sites and such sites are not governed by this privacy
              statement. You should exercise caution and look at the privacy
              statement applicable to the website in question.
            </p>
            <h3>Your rights</h3>
            <p style={{ padding: "1rem 0" }}>
              You have the right to request access to the personal information
              that we hold about you, and to request that it be corrected,
              updated, or deleted. If you would like to exercise these rights,
              please contact us using the information provided below.
            </p>
            <h3>Updates to this privacy policy</h3>
            <p style={{ padding: "1rem 0" }}>
              We may update this privacy policy from time to time by posting a
              new version on our website. You should check this page
              occasionally to ensure that you are happy with any changes.
            </p>
            <h3>Contact us.</h3>
            <p style={{ padding: "1rem 0" }}>
              If you have any questions about this privacy policy or our
              treatment of your personal information, please contact us at
              info@adgrouptech.com
            </p>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default PrivacyAndPolicy;
