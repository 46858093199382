import iconOne from "../src/assets/website icon/002-virtual reality.png";
import iconTwo from "../src/assets/website icon/011-connect.png";
import iconThree from "../src/assets/website icon/icons-03.png";
import iconFour from "../src/assets/website icon/012-brain.png";
import iconFive from "../src/assets/website icon/icons-02.png";
import iconsixth from "../src/assets/website icon/icons-01.png";
import iconSeventh from "../src/assets/website icon/016-conference.png";
import iconEighth from "../src/assets/website icon/019-interactive.png";
import iconNinth from "../src/assets/website icon/029-simulation.png";
import iconTenth from "../src/assets/website icon/040-book.png";
import iconEleventh from "../src/assets/website icon/041-hologram.png";
import iconTewlveth from "../src/assets/website icon/012-brain.png";
export let arr = [
  {
    header: "Increasing Student Engagement",
    icon: iconOne,
    description: `smarty transforms educational content delivery, allowing students to learn in a more engaging and effective way. By ‎creating a fully immersive, rich virtual world that gives the student the chance not ‎only to see it, ‎but also to interact with it, and get accurate responses based on real-world ‎possibilities, which ‎motivates student engagement and participation. ‎`,
    direction:"left"
  },
  {
    header: "School Special Tailored Content",
    icon: iconTwo,
    description: `If there was one feature that will make our solution unrivalled, it will be this feature. All other solutions in global market give you a hundred of VR and AR topics and you choose which ones fits your school and curriculum, But for our solution we design your own curriculum whatever it’s for which grade and in any language.   `,
    direction:"left"
  },
  // {
  //   header: "SMARTY Virtual Field Trips",
  //   icon: iconThree,
  //   description: ` SMARTY Virtual Field Trips give students the chance to visit
  //   places they could never visit in person ‎or have only read
  //   about. Neither time nor distance limit them. ‎Instead of taking
  //   a traditional field trip, SMARTY VR Trip allows students to
  //   experience a more ‎immersive learning experience in the safety
  //   and comfort of their classrooms.`,
  // },
  {
    header: "Experience-Based Learning",
    icon: iconFour,
    description: `Smarty Virtual Reality (VR) provides an experience-based learning, instead of the traditional methods ‎of reading and writing, where the students are totally immersed in the digital world; they can turn their ‎heads to explore the world, use their hands to pull a handle, and physically walk to move around the ‎virtual environment, which engages students in a unique and powerful way that isn't likely to forget. `,
    direction:"left"
  },
  {
    header: "Virtual Field Trip",
    icon: iconFive,
    description: `Smarty Virtual Field Trip gives students the chance to visit places they could never visit in person ‎or have only read about. Neither time nor distance limit them. ‎Instead of taking a traditional field trip, we allow students to experience a more ‎immersive learning experience in the safety and comfort of their classrooms. `,
    direction:"left"
  },
  {
    header: "High Tech Training",
    icon: iconsixth,
    description: `Smarty is a powerful tool to train people to acquire a wide variety of skills, such as technical, ‎procedural, safety, people skills and emotional intelligence. smarty engages learners in ‎simulated real-time places and situations, where mistakes have no real-world consequences, in ‎a safe, controlled, and responsive environment that reduces risks, time and costs, and ‎increases efficiency, emotional responses and memory retention. ‎`,
    direction:"right"
  },
  // {
  //   header: "Making Learning Interesting",
  //   icon: iconSeventh,
  //   description: `SMARTY Augmented Reality (AR)-based learning makes the learning
  //   process more dynamic ‎and interesting, which enhances classroom
  //   experience through making it interactive and ‎promoting higher
  //   engagement, thus inspires students’ minds, and makes them
  //   excited and ‎interested to explore new subjects.`,
  // },
  {
    header: "Integrated learning ",
    icon: iconEighth,
    description: `Augmented Reality (AR) is a highly visual, interactive version of the physical world that ‎superimposes sounds, videos, and graphics onto an existing environment. Smarty offers a combined mode of learning with traditional worksheets, textbooks, and ‎lessons, where they can access any content by scanning the image on a particular App for ‎studying. Such a combination of interactive education and engaging content can cause the ‎students to remember what they have learned and gain skills and information faster. ‎`,
    direction:"right"

  },
  // {
  //   header: "Reduced Costs",
  //   icon: iconNinth,
  //   description: `SMARTY Augmented Reality (AR) classroom offers more
  //   cost-friendly options to schools with ‎tight budgets, since it
  //   only requires minimal setup like; mobile devices with an
  //   internet ‎connection and Augmented Reality (AR) Apps, while
  //   still providing the same features ‎and benefits, compared to
  //   traditional learning tools.`,
  // },
  {
    header: "Fun way to learn",
    icon: iconThree,
    description: `Smarty Augmented Reality (AR)-based learning makes the learning process more dynamic ‎and interesting, which enhances classroom experience through making it interactive and ‎promoting higher engagement, thus inspires students' minds, and makes them excited and ‎interested to explore new subjects.‎`,
    direction:"right"

  },
  {
    header: "Interactive Sessions ",
    icon: iconSeventh,
    description: `Smarty Augmented Reality (AR) and Virtual Reality (VR) based classroom lessons (Smarty ‎Class) enable students to engage with one another, since smarty grants the ‎opportunities to implement first-hand learning approaches that make the students more ‎motivated and actively involved in the learning process.‎`,
    direction:"right"

  },
  {
    header: "Understanding complex concepts",
    icon: iconThree,
    description: `With the use of 3D models, interactive illustrations and visual methods of teaching, smarty ‎helps students grasp complex concepts and get a practical view of things faster, easier and ‎achieve higher results in their educational process.‎`,
  },
  {
    header: "Everywhere is a classroom",
    icon: iconTewlveth,
    description: `One of the key features of smarty Educational Apps is that we code objects in a way that the ‎Augmented Reality App and (VR)will be able to read it anytime, anywhere, by just holding the camera ‎in front of the object. smarty eliminates the barriers of time and location; thus, students no ‎longer must be physically present in the classroom and yet, they will get the same hands-on ‎education, and they can learn at their own pace.‎`,
  },
  {
    header: "Reduced Costs",
    icon: iconEleventh,
    description: `Smarty solutions offer more cost-friendly options to schools with ‎tight budgets, since it only requires minimal setup like VR headset and a laptop for VR solution or a mobile device with an internet ‎connection and Apps for AR solution, while still providing the same features ‎and benefits, compared to traditional learning tools.‎`,
  },
  {
    header: "Content Variety",
    icon: iconNinth,
    description: `Smarty provides not only a variety of educational applications and ‎contents like Mathematics, Science, Biology and Chemistry, but extends to the medical and ‎healthcare training, scientific field, architecture, interior design and modelling, retail industry, ‎tourism, entertainment, space industry and military intelligence. In short, smarty invades all occupations.`,
  },
  {
    header: "Developing Creativity",
    icon: iconTenth,
    description: `smarty improves students' creativity and develops their imaginative and ‎thinking abilities and is proving to be a valuable tool for encouraging students to create ‎their own 3D content for themselves and their peers. smarty opens ‎endless pedagogical possibilities.‎`,
  },
];
