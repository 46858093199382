import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import logo from "../assets/rsz_logo-f.webp";
import { StyledLink } from "../style/NavBarStyle";
import { NavLink, useParams } from "react-router-dom";
const ServiceNavBar = () => {
  const { id } = useParams();
  return (
    <Grid container columns={12}>
      <Grid item xs={12}>
        <Nav>
          <nav className="navbar">
            <div className="navbar-container container">
              <input type="checkbox" name="" id="" />
              <div className="hamburger-lines">
                <span class="line line1"></span>
                <span class="line line2"></span>
                <span class="line line3"></span>
              </div>
              <ul className="menu-items">
                <NavLink to="about">
                  {" "}
                  <li>
                    <div className="circle"></div> About
                  </li>
                </NavLink>
                {id === "19" ? (
                  <>
                    <NavLink to="activites">
                      <li>
                        <div className="circle"></div>Activities
                      </li>
                    </NavLink>
                  </>
                ) : (
                  <>
                    {" "}
                    <NavLink to="how-it-works">
                      <li>
                        <div className="circle"></div>How it works
                      </li>
                    </NavLink>
                  </>
                )}
                {id === "19" ? (
                  <>
                    <NavLink to="activites">
                      <li>
                        <div className="circle"></div>Activities
                      </li>
                    </NavLink>
                  </>
                ) : (
                  <>
                    {" "}
                    <NavLink to="content">
                      {" "}
                      <li>
                        <div className="circle"></div>
                        {id === "9" ||
                        id === "09" ||
                        id === "10" ||
                        id === "11" ? (
                          <>Catalogue</>
                        ) : (
                          <>Content</>
                        )}
                      </li>
                    </NavLink>
                  </>
                )}
                {id === "11" ? (
                  <>
                    <NavLink to="partners">
                      {" "}
                      <li>
                        <div className="circle"></div>
                        Partners
                      </li>
                    </NavLink>
                  </>
                ) : (
                  <></>
                )}
                {id === "6" ? (
                  <>
                    <NavLink to="how-diffrent">
                      {" "}
                      <li>
                        <div className="circle"></div> How we are diffrent
                      </li>
                    </NavLink>
                    <NavLink to="headset">
                      {" "}
                      <li>
                        <div className="circle"></div> Headset
                      </li>
                    </NavLink>
                  </>
                ) : (
                  <></>
                )}
                <NavLink to="booke-demo">
                  <li>
                    <div className="circle"></div>Book a demo
                  </li>
                </NavLink>{" "}
              </ul>
              <NavLink to="/">
                <img src={logo} alt="" className="logo" />
              </NavLink>
            </div>
          </nav>
        </Nav>
      </Grid>
    </Grid>
  );
};
const Nav = styled.nav`
  /* background-color: black; */
  /* background-color: red; */

  width: 100%;
  .navbar input[type="checkbox"],
  .navbar .hamburger-lines {
    display: none;
  }

  .navbar {
    position: fixed;
    width: 100%;
    color: white;
    height: 50px;
    z-index: 12;
  }

  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 64px;
    align-items: center;
    padding: 0 2rem;
    /* background-color: black; */
    backdrop-filter: blur(5px);
  }

  .menu-items {
    order: 2;
    display: flex;
    a.active {
      color: #037ef3;
      font-weight: 700;
      position: relative;
      font-size: 1.2rem;
      li {
        &:after {
          display: block;
        }
      }
    }
  }

  .menu-items li {
    list-style: none;
    margin-left: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 3px;
      background: #037ef3;
      display: none;
    }
  }

  .menu-items a {
    text-decoration: none;
    color: white;
    font-weight: normal;
    transition: color 0.3s ease-in-out;
  }

  .menu-items a:hover {
    color: #0164c4;
    transition: color 0.3s ease-in-out;
  }

  .logo {
    order: 1;
    font-size: 2.3rem;
    /* margin-bottom: 0.5rem; */
  }
  @media (max-width: 768px) {
    .navbar {
      opacity: 0.95;
    }

    .navbar-container input[type="checkbox"],
    .navbar-container .hamburger-lines {
      display: block;
    }

    .navbar-container {
      display: block;
      position: relative;
      height: 64px;
    }

    .navbar-container input[type="checkbox"] {
      position: absolute;
      display: block;
      height: 32px;
      width: 30px;
      top: 20px;
      left: 20px;
      z-index: 5;
      opacity: 0;
    }

    .navbar-container .hamburger-lines {
      display: block;
      height: 23px;
      width: 35px;
      position: absolute;
      top: 17px;
      left: 20px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .navbar-container .hamburger-lines .line {
      display: block;
      height: 4px;
      width: 100%;
      border-radius: 10px;
      background: white;
    }

    .navbar-container .hamburger-lines .line1 {
      transform-origin: 0% 0%;
      transition: transform 0.4s ease-in-out;
    }

    .navbar-container .hamburger-lines .line2 {
      transition: transform 0.2s ease-in-out;
    }

    .navbar-container .hamburger-lines .line3 {
      transform-origin: 0% 100%;
      transition: transform 0.4s ease-in-out;
    }

    .navbar .menu-items {
      padding-top: 100px;
      background: black;
      height: 100vh;
      max-width: 300px;
      transform: translate(-150%);
      display: flex;
      flex-direction: column;
      margin-left: -40px;
      padding-left: 50px;
      transition: transform 0.5s ease-in-out;
      /* box-shadow: 5px 0px 10px 0px #aaa; */
      backdrop-filter: blur(15px);
      color: black;
      a.active {
        color: #037ef3;
        font-weight: 700;
        position: relative;
        font-size: 1.2rem;
        li {
          &:after {
            display: block;
          }
        }
      }
    }

    .navbar .menu-items li {
      margin-bottom: 1.5rem;
      font-size: 1.3rem;
      font-weight: 500;
      color: white;
      &:after {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 50%;
        height: 3px;
        background: #037ef3;
        display: none;
      }
    }

    .logo {
      position: absolute;
      top: 5px;
      right: 15px;
      font-size: 2rem;
    }

    .navbar-container input[type="checkbox"]:checked ~ .menu-items {
      transform: translateX(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
      transform: rotate(35deg);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
      transform: scaleY(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
      transform: rotate(-35deg);
    }
  }
`;

export default ServiceNavBar;
