import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StyledLink } from "../../style/NavBarStyle";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import { Pagination, EffectCoverflow, Autoplay, Navigation } from "swiper";

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  lazyLoad: true,
};
const pagination = {
  clickable: true,
  renderBullet: function (index, className) {
    return '<span class="' + className + '">' + "</span>";
  },
};
const AboutService = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { singleService, allServices } = useSelector(
    (state) => state?.services
  );
  let smartyServices = allServices?.filter((e) => e.company_id === 2);
  return (
    <Bigsection>
      <Grid container columns={12}>
        {/* <Grid item xs={12} sx={{paddingBottom:"5rem"}}>
          <h3 className="header">Ar Service</h3>
        </Grid> */}
        {/* <Grid item xs={12}> */}
        {/* <p className="header">About</p> */}
        {/* </Grid> */}
        <Grid item xs={12}>
          <p className="lorem">{singleService?.description}</p>
        </Grid>
        <Grid item xs={12} className="second">
          <p className="header">Watch a trailer</p>
          <iframe
            width="100%"
            height="500px"
            src={singleService?.video_url}
            title="Youtube Player"
            frameborder="0"
            allowFullScreen
          />
        </Grid>
        <Grid container columns={12} className="slide">
          {/* <Grid item xs={12}>
            <Slider {...settings}>
              {singleService?.image_services.map((e) => {
                return (
                  <div>
                    <img src={e?.image_path}></img>
                  </div>
                );
              })}
            </Slider>
          </Grid> */}
          <Grid xs={12}>
            <p className="header">Title</p>
            <p className="newLorem">
              {" "}
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo hic
              ab temporibus numquam! Eligendi fuga at quae quidem expedita ad
              fugiat illum voluptates eaque quos, cupiditate voluptate ipsum
              exercitationem asperiores. Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Quo hic ab temporibus numquam! Eligendi fuga at
              quae quidem expedita ad fugiat illum voluptates eaque quos,
              cupiditate voluptate ipsum exercitationem asperiores. Lorem ipsum
              dolor sit amet consectetur adipisicing elit. Quo hic ab temporibus
              numquam! Eligendi fuga at quae quidem expedita ad fugiat illum
              voluptates eaque quos, cupiditate voluptate ipsum exercitationem
              asperiores. Lorem ipsum dolor sit amet consectetur adipisicing
              elit. Quo hic ab temporibus numquam! Eligendi fuga at quae quidem
              expedita ad fugiat illum voluptates eaque quos, cupiditate
              voluptate ipsum exercitationem asperiores. Lorem ipsum dolor sit
              amet consectetur adipisicing elit. Quo hic ab temporibus numquam!
              Eligendi fuga at quae quidem expedita ad fugiat illum voluptates
              eaque quos, cupiditate voluptate ipsum exercitationem asperiores.
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo hic
              ab temporibus numquam! Eligendi fuga at quae quidem expedita ad
              fugiat illum voluptates eaque quos, cupiditate voluptate ipsum
              exercitationem asperiores. Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Quo hic ab temporibus numquam! Eligendi fuga at
              quae quidem expedita ad fugiat illum voluptates eaque quos,
              cupiditate voluptate ipsum exercitationem asperiores. Lorem ipsum
              dolor sit amet consectetur adipisicing elit. Quo hic ab temporibus
              numquam! Eligendi fuga at quae quidem expedita ad fugiat illum
              voluptates eaque quos, cupiditate voluptate ipsum exercitationem
              asperiores.
            </p>
          </Grid>
          <Grid item xs={12} pt={5}>
            <h3 className="header">More Services</h3>
            <div className="other_services">
              <Swiper
                effect={"coverflow"}
                slidesPerView={3}
                spaceBetween={30}
                grabCursor={true}
                centeredSlides={true}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={pagination}
                modules={[Autoplay, EffectCoverflow, Pagination]}
                className="mySwiper"
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                }}
              >
                {smartyServices?.map((e, i) => {
                  return (
                    <SwiperSlide>
                      <StyledLink to={`/services/${e?.id}/about`}>
                        <div key={i} className="single_service">
                          <img src={e?.thumbnail_path}></img>
                          <p>{e?.title}</p>
                        </div>
                      </StyledLink>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {/* <p className="header">Most comfortable VR Headset</p>
      <Grid container columns={12} alignItems="center" spacing={2}>
        <Grid item xs={12} md={6} className="desc">
          <p>
            We are using most comfortable virtual reality headset, Standalone
            and wireless!
          </p>
          <p>* Qualcomm Processor, 4GB RAM</p>
          <p>* 3K Resolution</p>
          <p>* Comfortable</p>
        </Grid>
        <Grid item xs={12} md={6}>
          <img className="vr" src={vr}></img>
        </Grid>
      </Grid>
      <Grid container columns={12} alignItems="center" spacing={2}>
        <Grid item xs={12} md={6}>
          <img className="vr" src={portrait}></img>
        </Grid>
        <Grid item xs={12} md={6} className="desc">
          <p>
            We are using most comfortable virtual reality headset, Standalone
            and wireless!
          </p>
          <p>* Qualcomm Processor, 4GB RAM</p>
          <p>* 3K Resolution</p>
          <p>* Comfortable</p>
        </Grid>
      </Grid> */}
    </Bigsection>
  );
};
const Bigsection = styled.div`
  padding: 0 4rem;
  .header {
    text-align: left !important;
    text-transform: uppercase;
    background: transparent linear-gradient(100deg, #ffffff 0%, #377ceb 100%) 0%
      0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 25px;
    font-weight: bold;
    /* width: 340px; */
    /* text-shadow: 2px 2px black; */
  }
  .second {
    padding: 3rem 0;
    p {
      padding: 2rem 0;
    }
  }
  .lorem {
    line-height: 35px;
    padding-top: 2rem;
    /* background-color: black; */
    opacity: 0.8;
    width: 90%;
    text-align: center;
    margin: auto;
  }
  .video,
  iframe {
    width: 100%;
    border: 1px solid #27549e;
    border-radius: 10px;
    cursor: pointer;
  }
  .vr {
    width: 90%;
  }
  .desc {
    font-size: 18px;
    p {
      line-height: 45px;
    }
  }
  .slide {
    img {
      width: 100%;
      padding: 0 10px;
    }
  }
  .other_services {
    display: flex;

    a {
      color: white;
      text-decoration: none;
    }
  }
  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-navigation {
    background-color: white;
  }
  .single_service {
    width: 100%;
    text-align: center;
    margin: auto;
    margin: 2rem auto;
    flex: 1 1 10px;
    img {
      width: 100%;
    }
    &:hover {
      opacity: 1;
    }
    a {
      color: white !important;
    }
  }
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: white;
    margin-top: 10px;
    padding-top: 10px;
  }

  .swiper-pagination-bullet-active {
    color: #fff;
    background: #007aff;
  }
  .newLorem {
    max-width: 90%;
    text-align: justify;
    text-justify: inter-word;
    word-spacing: 3px;
    opacity: 0.8;
    text-indent: 50px;
    margin: 2rem 0;
  }
  @media (max-width: 760px) {
    .lorem {
      line-height: 35px;
      padding-top: 2rem;
      /* background-color: black; */
      opacity: 0.8;
      width: 100%;
    }
  }
`;
export default AboutService;
