import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import img from "../../assets/video2.webp";
import { useLocation } from "react-router-dom";
import ReadMore from "../ReadMore";
const HowDiffrenet = () => {
  const locaion = useLocation();
  const id = locaion?.pathname?.split("/")[2];
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Bigsection>
      <Grid container columns={12}>
        <Grid item xs={12} container spacing={2}>
          {/* <p className="headset">How we are different</p> */}
          {id === "6" ? (
            <>
              {" "}
              <Grid item xs={12} md={6}>
                <div className="diffev">
                  <h4 className="header">Customized Content:</h4>
                  <p className="lorem">
                    <ReadMore>
                      The main feature of smarty VCLab is its ability to provide
                      customized content based on the specific requirements of
                      each school’s curriculum. Due to this, Smarty platform is
                      totally unique compared to any other similar service
                      providers.
                    </ReadMore>
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="diffev">
                  <h4 className="header">Teacher Control:</h4>
                  <p className="lorem">
                    <ReadMore>
                      Teacher's control in the classroom refers to their ability
                      to manage and maintain an effective learning environment
                      for their students. Using VR in classroom has 2 main
                      obstacles, first is that the teacher will wear the a VR
                      headset like the students but in this case he won’t be
                      able to see if all students are using it well or even
                      involved in the session. Second, is not wearing the VR
                      headset and in this case, he can’t follow with students
                      and see their interactivity with the content. Our Solution
                      has solved this problem by making the teacher has a laptop
                      with the same content like the students but with more
                      teaching features to control the lesson content, he will
                      be able also to manage each student separately from his
                      laptop.
                    </ReadMore>
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="diffev">
                  <h4 className="header">Continuous update:</h4>
                  <p className="lorem">
                    <ReadMore>
                      Another main feature that sets us apart from the
                      competitors is that We consistently update our content, as
                      we use online content for teacher and students, so we can
                      apply all updates immediately.
                    </ReadMore>
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="diffev">
                  <h4 className="header">Students can join anywhere</h4>
                  <p className="lorem">
                    <ReadMore>
                      If you have an absent student or more for one day or weeks
                      our platform provides you with the best solution for that,
                      just give him one of our headsets and he will join the
                      class with his teacher and his classmates wherever he is.
                    </ReadMore>
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="diffev">
                  <h4 className="header">Instant Evaluation:</h4>
                  <p className="lorem">
                    <ReadMore>
                      Our platform allows teachers to get immediate feedback
                      from students for their understanding and interaction with
                      the lesson topic, we offer activities and quizzes with
                      each lesson to measure the students progress and the
                      teacher will get the result for all students in a separate
                      file.
                    </ReadMore>
                  </p>
                </div>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12}>
          <p className="headset">Watch a Trailer</p>
          <img className="vidoeTrailer" src={img}></img>
        </Grid>
        {/* <Grid container columns={12} alignItems="center" spacing={2}>
          <Grid item xs={12} md={6} className="desc">
            <p className="header">Most comfortable VR Headset</p>
            <p>
              We are using most comfortable virtual reality headset, Standalone
              and wireless!
            </p>
            <p>* Qualcomm Processor, 4GB RAM</p>
            <p>* 3K Resolution</p>
            <p>* Comfortable</p>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className="vr" src={vr}></img>
          </Grid>
        </Grid>
        <Grid
          container
          columns={12}
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={6}>
            <img className="vr" src={portrait}></img>
          </Grid>
          <Grid item xs={12} md={6} className="desc">
            <p className="header">Most comfortable VR Headset</p>
            <p>
              We are using most comfortable virtual reality headset, Standalone
              and wireless!
            </p>
            <p>* Qualcomm Processor, 4GB RAM</p>
            <p>* 3K Resolution</p>
            <p>* Comfortable</p>
          </Grid>
        </Grid> */}
      </Grid>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  padding: 0 4rem;
  .headset {
    text-align: left;
    text-transform: uppercase;
    background: transparent linear-gradient(98deg, #ffffff 0%, #377ceb 100%) 0%
      0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 25px;
    font-weight: bold;
    width: 340px;
    height: 32px;
  }
  .lorem {
    line-height: 35px;
    opacity: 0.8;
    width: 90%;
  }
  .vidoeTrailer {
    width: 100%;
    margin: 2rem 0;
  }
  .header {
    background: #ffffff;
    background: linear-gradient(to right, #ffffff 0%, #377ceb 10%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 25px;
  }
  .video {
    width: 100%;
    border: 1px solid #27549e;
    border-radius: 10px;
  }
  .vr {
    width: 70%;
    /* margin-top: 10px; */
  }
  .desc {
    font-size: 18px;
    p {
      line-height: 40px;
    }
  }
  .diffev {
    padding-bottom: 1.5rem;
  }
`;

export default HowDiffrenet;
