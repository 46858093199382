import styled from "styled-components";
// import background from "../assets/serviceBg.png";

export const ServiceStyle = styled.div`
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100vh; */
  text-align: center;
  padding: 1rem 0;
  background-color: #121212;
  .flexcontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 10rem;
    .singleDiv {
    }
  }
  .header {
    background: transparent
      linear-gradient(98deg, #2b2b2b 0%, #ffffff 0%, #4288ec 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 60px;
    font-weight: bold;
    background-clip: text;
    -webkit-background-clip: text;
  }
  .lorem {
    max-width: 60%;
    text-align: center;
    font-size: 14px;
    line-height: 25px;
    margin: auto;
  }
  .singleDiv {
    width: 70%;
    text-align: center;
    margin: auto;
    margin: 2rem auto;
    img {
      width: 100%;
    }
    &:hover {
      opacity: 1;
    }
  }
  .services {
    text-align: center;
    margin: auto;
    max-width: 80%;
  }
  .learn {
    color: #ffffff;
    border: 1px solid #1386f4;
    background-color: #037ef3;
    padding: 0.5rem 4rem;
    border-radius: 48px;
    font-size: 24px;
    cursor: pointer;
    margin: 0.5rem 0;
    margin-bottom: 2rem;
  }
  p {
    color: white;
  }
`;
