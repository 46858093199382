import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { catalogue } from "../../../Api/Api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const GetAllCatalogue = createAsyncThunk(
  "Catalogue/GET",
  async (_, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "GET",
        baseURL: `${catalogue}`,
        headers: headers,
      }).then((res) => {
        return res.data.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetSingleCatalogue = createAsyncThunk(
  "Catalogue/GET",
  async (id, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "GET",
        baseURL: `${catalogue}/${id}`,
        headers: headers,
      }).then((res) => {
        return res.data.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const AddCatalogue = createAsyncThunk(
  "catalogue/create",
  async (data, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      console.log("user", user);
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "POST",
        baseURL: `${catalogue}`,
        headers: headers,
        data: data,
      }).then((res) => {
        toast.success(`Catalogue has been Created`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const DeleteCataloguee = createAsyncThunk(
  "catalogue/delete",
  async (data, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "DELETE",
        baseURL: `${catalogue}/${data}`,
        headers: headers,
        // data: data,
      }).then((res) => {
        toast.success(`Catalogue has been deleted`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const EditAddCatalogue = createAsyncThunk(
  "catalogue/edit",
  async (data, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      console.log("data in request", data);
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "PATCH",
        baseURL: `${catalogue}/${data?.id}`,
        headers: headers,
        data: data,
      }).then((res) => {
        toast.success(`Catalogue has been edited`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const CatalogueSlice = createSlice({
  name: "Catalogues",
  initialState: {
    catalogueStatus: "",
    error: "",
    allCatalogue: [],
    Businesss: [],
  },
  reducers: {
    catalogueReset: (state) => {
      state.catalogueStatus = "";
    },
  },
  extraReducers: {
    [AddCatalogue.pending]: (state, { payload }) => {
      state.catalogueStatus = "loading";
    },
    [AddCatalogue.fulfilled]: (state, { payload }) => {
      state.catalogueStatus = "success";
    },
    [AddCatalogue.rejected]: (state, { payload }) => {
      state.catalogueStatus = "failed";
      state.error = payload;
    },
    [GetAllCatalogue.pending]: (state, { payload }) => {
      state.catalogueStatus = "loading";
    },
    [GetAllCatalogue.fulfilled]: (state, { payload }) => {
      state.catalogueStatus = "success";
      state.allCatalogue = payload;
    },
    [GetAllCatalogue.rejected]: (state, { payload }) => {
      state.catalogueStatus = "failed";
      state.error = payload;
    },
    [GetSingleCatalogue.pending]: (state, { payload }) => {
      state.catalogueStatus = "loading";
    },
    [GetSingleCatalogue.fulfilled]: (state, { payload }) => {
      state.catalogueStatus = "success";
      state.singleCatalogue = payload;
    },
    [GetSingleCatalogue.rejected]: (state, { payload }) => {
      state.catalogueStatus = "failed";
      state.error = payload;
    },
    [DeleteCataloguee.pending]: (state, { payload }) => {
      state.catalogueStatus = "loading";
    },
    [DeleteCataloguee.fulfilled]: (state, { payload }) => {
      state.catalogueStatus = "success";
      state.Businesss = payload;
    },
    [DeleteCataloguee.rejected]: (state, { payload }) => {
      state.catalogueStatus = "failed";
      state.error = payload;
    },
    [EditAddCatalogue.pending]: (state, { payload }) => {
      state.catalogueStatus = "loading";
    },
    [EditAddCatalogue.fulfilled]: (state, { payload }) => {
      state.catalogueStatus = "success";
    },
    [EditAddCatalogue.rejected]: (state, { payload }) => {
      state.catalogueStatus = "failed";
      state.error = payload;
    },
  },
});

export const { catalogueReset } = CatalogueSlice.actions;

export default CatalogueSlice.reducer;
