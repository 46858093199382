import styled from "styled-components";

export const Bigsection = styled.div`
  .blogContanier {
    padding: 3rem 3rem;
    // display: flex;
    // width: 81.8125rem;
    // height: 24.375rem;
    // align-items: flex-start;
    // gap: 1rem;
    flex-shrink: 0;
    // flex-wrap: wrap;
    // background: red;
    .singleDiv {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      // flex: 1 0 0;
      min-height: 50vh;
      align-self: stretch;
      border-bottom: 1px solid var(--grey-medium, #a887a8);
      h1 {
        color: var(--grey-darkest, #321433);
        font-family: Times New Roman;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 2.8rem */
        letter-spacing: -0.01875rem;
      }
      .photo {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        div {
          width: 3rem;
          height: 3rem;
          border-radius: 3rem;
          background: #d9d9d9;
        }
      }
      .date {
        color: #000;
        font-family: Open Sans;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 180%; /* 2.025rem */
        //   padding-bottom: 2rem;
      }
    }
    a {
      color: inherit;
    }
  }
  @media (max-width: 1000px) {
    .blogContanier {
      padding: 3rem 1rem;
    }
  }
`;
