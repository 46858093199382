import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { pages } from "../../../Api/Api";

export const GetAllPages = createAsyncThunk(
  "Pages/GET",
  async (_, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "GET",
        baseURL: `${pages}/company/2`,
        headers: headers,
      }).then((res) => {
        return res.data.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetSinglePage = createAsyncThunk(
  "Pages/GETSingle",
  async (data, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "GET",
        baseURL: `${pages}/${data}`,
        headers: headers,
      }).then((res) => {
        return res.data.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const AddPage = createAsyncThunk(
  "Pages/create",
  async (data, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      console.log("user", user);
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "POST",
        baseURL: `${pages}`,
        headers: headers,
        data: data,
      }).then((res) => {
        toast.success(`Page has been Created`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const DeletePage = createAsyncThunk(
  "Pages/delete",
  async (data, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "DELETE",
        baseURL: `${pages}/${data}`,
        headers: headers,
      }).then((res) => {
        toast.success(`Page has been deleted`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const EditPage = createAsyncThunk(
  "Pages/edit",
  async (data, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      console.log("data in request", data);
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "POST",
        baseURL: `${pages}/${data?.id}`,
        headers: headers,
        data: data.data,
      }).then((res) => {
        toast.success(`Page has been edited`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const PageSlice = createSlice({
  name: "Pages",
  initialState: {
    pagesStatus: "",
    error: "",
    allPages: [],
    pageData: "",
    singlePage: "",
  },
  reducers: {
    pagesReset: (state) => {
      state.pagesStatus = "";
    },
    saveData: (state, { payload }) => {
      state.pagesData = payload;
    },
  },
  extraReducers: {
    [AddPage.pending]: (state, { payload }) => {
      state.pagesStatus = "loading";
    },
    [AddPage.fulfilled]: (state, { payload }) => {
      state.pagesStatus = "success";
    },
    [AddPage.rejected]: (state, { payload }) => {
      state.pagesStatus = "failed";
      state.error = payload;
    },
    [GetAllPages.pending]: (state, { payload }) => {
      state.pagesStatus = "loading";
    },
    [GetAllPages.fulfilled]: (state, { payload }) => {
      state.pagesStatus = "success";
      state.allPages = payload;
    },
    [GetAllPages.rejected]: (state, { payload }) => {
      state.pagesStatus = "failed";
      state.error = payload;
    },
    [DeletePage.pending]: (state, { payload }) => {
      state.pagesStatus = "loading";
    },
    [DeletePage.fulfilled]: (state, { payload }) => {
      state.pagesStatus = "success";
      //   state.Businesss = payload;
    },
    [DeletePage.rejected]: (state, { payload }) => {
      state.pagesStatus = "failed";
      state.error = payload;
    },
    [EditPage.pending]: (state, { payload }) => {
      state.pagesStatus = "loading";
    },
    [EditPage.fulfilled]: (state, { payload }) => {
      state.pagesStatus = "success";
    },
    [EditPage.rejected]: (state, { payload }) => {
      state.pagesStatus = "failed";
      state.error = payload;
    },
    [GetSinglePage.pending]: (state, { payload }) => {
      state.pagesStatus = "loading";
    },
    [GetSinglePage.fulfilled]: (state, { payload }) => {
      state.pagesStatus = "success";
      state.singlePage = payload;
    },
    [GetSinglePage.rejected]: (state, { payload }) => {
      state.pagesStatus = "failed";
      state.error = payload;
    },
  },
});

export const { pagesReset, saveData } = PageSlice.actions;

export default PageSlice.reducer;
