import { Grid } from "@mui/material";
import React from "react";
import { FooterStyle } from "../style/FooterStyle";
import facebook from "../assets/social media icons/facebook.png";
import instgram from "../assets/social media icons/instagram.png";
import linkedin from "../assets/social media icons/linkedin.png";
import youtube from "../assets/social media icons/youtube.png";
import MyMaps from "./GoogleMaps/MyMaps";
const Footer = () => {
  return (
    <FooterStyle>
      <Grid
        container
        columns={12}
        spacing={8}
        justifyContent="center"
        alignItems="start"
      >
        <Grid item xs={12} md={4} lg={3}>
          <ul className="firstUl">
            <li>
              <a href="https://adgrouptech.com/" target="_blank">
                <img src={"https://adgrouptech.com/ad-logo.svg"}></img>
              </a>
            </li>
            <li>
              <p>
                We’re on a mission to constantly evolve the way digital and
                integrated solutions are done so that we can enable working
                smart and not just hard.
              </p>
            </li>
            <li>
              <a
                href="https://www.facebook.com/people/Smarty-Tech/100088279747899/"
                target="_blank"
              >
                {" "}
                {/* <FacebookIcon></FacebookIcon>{" "} */}
                <img src={facebook} alt="" className="imageFilter" />
              </a>
              <a href="https://www.instagram.com/smarty_edu/" target="_blank">
                {" "}
                {/* <InstagramIcon></InstagramIcon> */}
                <img src={instgram} alt="" className="imageFilter" />
              </a>
              <a href="https://www.linkedin.com/company/smarty-edu/">
                {" "}
                <img src={linkedin} alt="" className="imageFilter" />
              </a>{" "}
              <a href="https://www.youtube.com/@smartyedu" target="_blank">
                {/* <YouTubeIcon></YouTubeIcon> */}
                <img src={youtube} alt="" className="imageFilter" />
              </a>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} md={4} lg={3} style={{ paddingLeft: "120px" }}>
          <h3>Quick Links</h3>
          <ul>
            <li>News & Articles</li>
            <li>Privacy & policy</li>
            <li>Careers</li>
            <li>Sister companies</li>
            <li>Our Projects</li>
          </ul>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <h3>Contact us</h3>
          <ul className="padding">
            <li>Smarty@adgrouptech.com</li>
            <li>
              Fifth Settlement, Cairo, Egypt <p>+201013645828</p>
            </li>
            <li>
              Tornado Tower, Doha, Qatar
              <p>00974 30555650</p>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <ul>
            <li>{/* <MyMaps></MyMaps> */}</li>
          </ul>
        </Grid>
      </Grid>
    </FooterStyle>
  );
};

export default Footer;
