import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import img from "../../assets/video2.webp";
import { useLocation } from "react-router-dom";
const Headset = () => {
  const location = useLocation();
  const id = location?.pathname?.split("/")[2];
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Bigsection>
      <Grid container columns={12}>
        <Grid item xs={12}>
          <p className="lorem">
            {id === "6" ? (
              <>
                {" "}
                We are using All in one VR headset, which means that users don’t
                need to connect to PC or any other devices. Our headset provides
                immersive and interactive learning experiences that can enhance
                engagement and retention of information. It’s designed for all
                ages, it’s comfortable, easy to use and with logic cost.
              </>
            ) : (
              <></>
            )}
          </p>
        </Grid>
        <Grid item xs={12}>
          <p className="headset">Watch a Trailer</p>
          <img className="vidoeTrailer" src={img}></img>
        </Grid>
        {/* <Grid container columns={12} alignItems="center" spacing={2}>
          <Grid item xs={12} md={6} className="desc">
            <p className="header">Most comfortable VR Headset</p>
            <p>
              We are using most comfortable virtual reality headset, Standalone
              and wireless!
            </p>
            <p>* Qualcomm Processor, 4GB RAM</p>
            <p>* 3K Resolution</p>
            <p>* Comfortable</p>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className="vr" src={vr}></img>
          </Grid>
        </Grid> */}
        {/* <Grid
          container
          columns={12}
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={6}>
            <img className="vr" src={portrait}></img>
          </Grid>
          <Grid item xs={12} md={6} className="desc">
            <p className="header">Most comfortable VR Headset</p>
            <p>
              We are using most comfortable virtual reality headset, Standalone
              and wireless!
            </p>
            <p>* Qualcomm Processor, 4GB RAM</p>
            <p>* 3K Resolution</p>
            <p>* Comfortable</p>
          </Grid>
        </Grid> */}
      </Grid>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  padding: 0 4rem;
  .headset {
    text-align: left;
    text-transform: uppercase;
    background: transparent linear-gradient(98deg, #ffffff 0%, #377ceb 100%) 0%
      0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 25px;
    font-weight: bold;
    width: 241px;
    height: 32px;
    margin: 2rem 0;
  }
  .lorem {
    line-height: 35px;
    /* padding-top: 2rem; */
    opacity: 0.8;
    width: 50%;
  }
  .vidoeTrailer {
    width: 100%;
  }
  .header {
    text-align: left !important;
    text-transform: uppercase;
    background: transparent linear-gradient(100deg, #ffffff 0%, #377ceb 100%) 0%
      0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 25px;
    font-weight: bold;
    width: 340px;
  }
  .video {
    width: 100%;
    border: 1px solid #27549e;
    border-radius: 10px;
  }
  .vr {
    width: 70%;
    /* margin-top: 10px; */
  }
  .desc {
    font-size: 18px;
    p {
      line-height: 40px;
    }
  }

  @media (max-width: 760px) {
    .lorem {
      line-height: 35px;
      padding-top: 2rem;
      opacity: 0.8;
      width: 100%;
    }
  }
`;

export default Headset;
