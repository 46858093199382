import { Grid } from "@mui/material";
import React from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
// import BG from "../assets/servicesBG/1917-x-3407-3-min.png";
import bb from "../assets/zztop.webp";
import { BackButtonStyle } from "../components/Layout/BackButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllServices,
  GetSingleService,
  servicesReset,
} from "../Redux/Slices/ServicesSlice/ServicesSlice";
import { useLocation } from "react-router-dom";
import Loader from "../components/Loader";
import ServiceNavBar from "../components/ServiceNavBar";

const Service = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { singleService, servicesStatus, allServices } = useSelector(
    (state) => state?.services
  );
  console.log("id", id);

  React.useEffect(() => {
    let apis = async () => {
      await dispatch(GetSingleService(id));
      await dispatch(GetAllServices());
      await dispatch(servicesReset());
    };
    apis();
  }, [id]);
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/#services");
  };
  let smartyServices = allServices?.filter((e) => e.company_id === 2);

  const [imageLoading, setImageLoading] = React.useState(true);
  React.useEffect(() => {
    const img = new Image();
    img.src = singleService?.background_path;
    img.onload = () => {
      console.log("Loaded");
      setImageLoading(false);
    };
  }, [singleService?.background_path]);
  return (
    <>
      {servicesStatus === "loading" ? (
        <>
          <Loader></Loader>
        </>
      ) : (
        <>
          <ServiceNavBar />
          <Bigsection image={singleService?.background_path}>
            <div className="main">
              <Grid container columns={12} justifyContent="center">
                <div className="hero">
                  {/* <Grid container columns={12} sx={{ paddingBottom: "5rem" }}> */}
                  <Grid item xs={12}>
                    <h1 className="MiddleHeader"> {singleService?.title}</h1>
                  </Grid>
                  {/* </Grid> */}
                </div>
                {/* <Grid item xs={12} md={2}>
                  <nav class="navbar">
                    <div class="navbar-container container">
                      <input type="checkbox" name="" id="" />
                      <div class="hamburger-lines">
                        <span class="line line1"></span>
                        <span class="line line2"></span>
                        <span class="line line3"></span>
                      </div>
                      <ul className="menu-items">
                        <NavLink to="about">
                          {" "}
                          <li>
                            <div className="circle"></div> About
                          </li>
                        </NavLink>
                        <NavLink to="how-it-works">
                          <li>
                            <div className="circle"></div>How it works
                          </li>
                        </NavLink>
                        <NavLink to="content">
                          {" "}
                          <li>
                            <div className="circle"></div>
                            {id === "9" ||
                            id === "09" ||
                            id === "10" ||
                            id === "11" ? (
                              <>Catalogue</>
                            ) : (
                              <>Content</>
                            )}
                          </li>
                        </NavLink>
                        {id === "11" ? (
                          <>
                            <NavLink to="partners">
                              {" "}
                              <li>
                                <div className="circle"></div>
                                Partners
                              </li>
                            </NavLink>
                          </>
                        ) : (
                          <></>
                        )}
                        {id === "6" ? (
                          <>
                            <NavLink to="how-diffrent">
                              {" "}
                              <li>
                                <div className="circle"></div> How we are
                                diffrent
                              </li>
                            </NavLink>
                            <NavLink to="headset">
                              {" "}
                              <li>
                                <div className="circle"></div> Headset
                              </li>
                            </NavLink>
                          </>
                        ) : (
                          <></>
                        )}
                        <NavLink to="booke-demo">
                          <li>
                            <div className="circle"></div>Book a demo
                          </li>
                        </NavLink>
                      </ul>
                    </div>
                  </nav>
                </Grid> */}
                <Grid item xs={12}>
                  <div className="rightside">
                    <Outlet></Outlet>
                  </div>
                </Grid>
              </Grid>
            </div>

            <Footer></Footer>
          </Bigsection>
        </>
      )}
    </>
  );
};
const Bigsection = styled.div`
  position: relative;
  /* background-image: url(${bb}); */
  background-image: url(${(props) => props.image});
  min-height: 100vh;
  /* background-attachment: fixed; */
  background-position: top;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: cover;
  text-align: left;
  background-color: black;
  color: white;
  padding-top: 3rem;
  .MiddleHeader {
    text-align: center;
    text-transform: uppercase;
    /* background: transparent linear-gradient(100deg, #ffffff 0%, #377ceb 100%) 0%
      0% no-repeat padding-box; */
    color: #037ef3;
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent; */
    /* font-size: 50px; */
    /* -webkit-text-stroke-width: 0.5px; */
    /* -webkit-text-stroke-color: white; */
    font-weight: bold;
    padding: 2rem 0;
  }
  .main {
    ul {
      margin: auto;
      display: flex;
      flex-direction: column;
      list-style: none;
      padding-left: 5rem;
      /* border-right:1px solid white ; */
      li {
        font-weight: 400;
        font-size: 18px;
        padding: 1.5rem 0;
        opacity: 0.53;
        text-align: left;
        display: flex;
        align-items: center;
        position: relative;
        .circle {
          width: 12px;
          height: 12px;
          border-radius: 50px;
          background-color: white;
          margin: 0 0.8rem;
          display: none;
          position: absolute;
          left: -2.5rem;
        }
      }
      a {
        color: inherit;
        text-decoration: none;
      }
      a.active {
        li {
          opacity: 1;
        }
        .circle {
          display: block;
        }
      }
    }
  }
  .navbar input[type="checkbox"],
  .navbar .hamburger-lines {
    display: none;
  }

  .navbar {
    position: fixed;
    /* padding: 0 2rem; */
    /* backdrop-filter: blur(5px); */
    width: 100%;
    color: white;
    height: 50px;
    z-index: 12;
    left: 0;
    top: 20%;
  }

  .navbar-container {
    /* display: flex; */
    /* justify-content: space-between; */
    /* height: 64px; */
    /* align-items: center; */
  }

  .menu-items {
    order: 2;
    display: flex;
  }

  .menu-items li {
    list-style: none;
    margin-left: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
  }

  .menu-items a {
    text-decoration: none;
    color: white;
    font-weight: 500;
    transition: color 0.3s ease-in-out;
  }

  .menu-items a:hover {
    color: #0164c4;
    transition: color 0.3s ease-in-out;
  }

  .logo {
    order: 1;
    font-size: 2.3rem;
    margin-bottom: 0.5rem;
  }
  @media (max-width: 768px) {
    .navbar {
      opacity: 0.95;
    }

    .navbar-container input[type="checkbox"],
    .navbar-container .hamburger-lines {
      display: block;
    }

    .navbar-container {
      display: block;
      position: relative;
      height: 64px;
    }

    .navbar-container input[type="checkbox"] {
      position: absolute;
      display: block;
      height: 32px;
      width: 30px;
      top: 20px;
      left: 20px;
      z-index: 5;
      opacity: 0;
    }

    .navbar-container .hamburger-lines {
      display: block;
      height: 23px;
      width: 35px;
      position: absolute;
      top: 17px;
      left: 20px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .navbar-container .hamburger-lines .line {
      display: block;
      height: 4px;
      width: 100%;
      border-radius: 10px;
      background: white;
    }

    .navbar-container .hamburger-lines .line1 {
      transform-origin: 0% 0%;
      transition: transform 0.4s ease-in-out;
    }

    .navbar-container .hamburger-lines .line2 {
      transition: transform 0.2s ease-in-out;
    }

    .navbar-container .hamburger-lines .line3 {
      transform-origin: 0% 100%;
      transition: transform 0.4s ease-in-out;
    }

    .navbar .menu-items {
      padding-top: 100px;
      background: black;
      height: 100dvh;
      max-width: 300px;
      transform: translate(-150%);
      display: flex;
      flex-direction: column;
      margin-left: -40px;
      padding-left: 50px;
      transition: transform 0.5s ease-in-out;
      /* box-shadow: 5px 0px 10px 0px #aaa; */
      backdrop-filter: blur(15px);
      color: black;
    }

    .navbar .menu-items li {
      margin-bottom: 1.5rem;
      font-size: 1.3rem;
      font-weight: 500;
      color: white;
    }

    .logo {
      position: absolute;
      top: 5px;
      right: 15px;
      font-size: 2rem;
    }

    .navbar-container input[type="checkbox"]:checked ~ .menu-items {
      transform: translateX(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
      transform: rotate(35deg);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
      transform: scaleY(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
      transform: rotate(-35deg);
    }
  }
`;

export default Service;
