import React, { useLayoutEffect, useState } from "react";
import SingleBlogData from "./components/singleBlog";
import OtherBlogs from "./components/otherBlogs";
import { Grid } from "@mui/material";
import { Bigsection } from "./style";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import NavBar from "../../components/NavBar";
import NewNavBar from "../../components/NewNavBar";

const BlogData = () => {
  const params = useParams();
  const { id } = params;
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async () => {
    setIsLoading(true);
    axios
      .get(`https://octarback.adgrouptech.com/api/blogs/show/${id}`)
      .then(function (response) {
        // handle success
        setIsLoading(false);
        setData(response?.data?.data);
        console.log("response?.data?.data", response?.data?.data);
      })
      .catch(function (error) {
        setIsLoading(false);

        // handle error
        console.log(error);
      });
  };
  console.log("allBlogs", data);
  useLayoutEffect(() => {
    fetchData();
  }, [id]);
  if (isLoading) {
    return <Loader />;
  }
  console.log("params", params);
  console.log("data", data);
  // const { id } = searchParams;
  // // console.log("id", id);
  // const data = await fetchSingleBlog(id);
  // const res = await data;
  let x = {};
  let y = { name: "Ronny" };
  let z = { name: "Jhon" };
  x[y] = { name: "viky" };
  x[z] = { name: "Akki" };
  console.log(x[y]);
  return (
    <>
      <NewNavBar />
      <Bigsection>
        <div className="dynamicBlogContainer">
          <div>
            <Link to="/blogs">
              <button className="nav">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="#FFFFFF"
                >
                  <path
                    d="M9 18L3 12L9 6L10.4 7.4L6.8 11H21V13H6.8L10.4 16.6L9 18Z"
                    fill="black"
                  />
                </svg>

                <p> See all articles</p>
              </button>
            </Link>
          </div>
          <Grid container columns={12} pt={5} spacing={4}>
            <Grid item xs={12} md={9}>
              <SingleBlogData id={id} data={data} />
            </Grid>
            <Grid item xs={12} md={3}>
              <OtherBlogs />
            </Grid>
          </Grid>
        </div>
      </Bigsection>
    </>
  );
};

export default BlogData;
