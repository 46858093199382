import { Button, Grid } from "@mui/material";
import React from "react";
import { HeaderStyle } from "../style/HeaderStyle";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { StyledLink } from "../style/NavBarStyle";
import lottiesTest from "../assets/json/lottieTest3.json";
import { useSelector } from "react-redux";
import { Fade } from "react-awesome-reveal";
import LottieComponent from "./LottieComponent";

const Header = () => {
  const { allPages, singlePage } = useSelector((state) => state?.pages);
  const smartPages = allPages?.filter((e) => e.company_id === 2);
  const homeSection = smartPages?.filter((e) => e?.section === "home");
  const LastIndex = homeSection[homeSection?.length - 1];
  return (
    <HeaderStyle>
      <Grid
        container
        columns={12}
        justifyContent="space-between"
        alignItems="center"
        // spacing={10}
      >
        <Grid item md={6} xs={12}>
          <Fade>
            {" "}
            <div>
              <h3 className="header">{LastIndex?.title}</h3>
              <p className="lorem">
                {LastIndex?.description}
                {/* <span
                style={{
                  color: "#377ceb",
                  textDecoration: "underline",
                  fontWeight: "bolder",
                  fontSize: "20px",
                  letterSpacing: "1px",
                }}
              >
                AD Group
              </span>{" "} */}
                that offers schools, ‎students, parents, and teachers
                ground-breaking educational programs and services.‎
              </p>
              {/* <p className="lorem">
              By embracing digital technology and offering teachers and students
              engaging, fulfilling, and ‎impressive learning opportunities, we
              aim to improve educational quality, the learning process, ‎and
              skills that will enable students to be the creators of the future.
            </p> */}
            </div>
            <div className="buttons">
              <StyledLink to="/bookdemo">
                {" "}
                <Button className="demo">Get my free demo</Button>
              </StyledLink>
              <Button
                className="how"
                endIcon={<PlayCircleOutlineIcon></PlayCircleOutlineIcon>}
              >
                Explore
              </Button>
            </div>
          </Fade>
        </Grid>
        <Grid item md={6} xs={12} className="right-div">
          {/* <video width="750" height="550" autoPlay muted loop id="myVideo">
            <source src={video} type="video/mp4" />
          </video> */}
          <LottieComponent lottie={lottiesTest} />
        </Grid>
      </Grid>
    </HeaderStyle>
  );
};

export default Header;
