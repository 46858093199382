import React from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
// import 'mapbox-gl/dist/mapbox-gl.css';
import styled from "styled-components";
const MyMaps = () => {
  const mapContainer = React.useRef(null);
  const map = React.useRef(null);
  const [lng, setLng] = React.useState(-70.9);
  const [lat, setLat] = React.useState(42.35);
  const [zoom, setZoom] = React.useState(9);
  mapboxgl.accessToken =
    "pk.eyJ1IjoiYWRraCIsImEiOiJja3pmcGhrN2Uwc3YxMnZwY3U2ZXI0cmZsIn0.jtVriTDRR04Y54qegE8rgw";

  React.useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      zoom: zoom,
    });
    new mapboxgl.Marker()?.setLngLat([-70.9, 42.35])?.addTo(map.current);
  });

  return (
    <BigSection>
      <div className="container">
        <div ref={mapContainer} className="map-container"></div>
      </div>
    </BigSection>
  );
};
const BigSection = styled.div`
  .mapboxgl-map {
    -webkit-tap-highlight-color: rgb(0 0 0/0);
    font: 12px/20px Helvetica Neue, Arial, Helvetica, sans-serif;
    /* overflow: hidden; */
    position: relative;
    width: 100%;
    height: 100%;
    overflow: visible !important;
  }
  .sc-hBxehG .jqeWYQ .map-container .mapboxgl-map {
    -webkit-tap-highlight-color: rgb(0 0 0/0);
    font: 12px/20px Helvetica Neue, Arial, Helvetica, sans-serif;
    /* overflow: hidden; */
    position: relative;
    overflow: visible !important;
    width: 100%;
    height: 100%;
  }
  .container {
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

export default React.memo(MyMaps);
