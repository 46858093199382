import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper";
import CibCardSlider from "./Layout/CibCardSlider";
const WhysmartySlider = ({ arr }) => {
  return (
    <Bigsection>
      <Swiper
        navigation={true}
        modules={[Navigation, Pagination]}
        className="mySwiper"
      >
        {arr.map((e, i) => {
          return (
            <SwiperSlide key={i}>
              <CibCardSlider card={e}></CibCardSlider>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  margin: 1rem;
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export default WhysmartySlider;
