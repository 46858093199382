import styled from "styled-components";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

export const NavStyle = styled.div`
  padding: 0 2rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  list-style: none;
  align-items: center;
  width: 100%;
  z-index: 200;
  background-color: black;
  flex-wrap: wrap;
  align-items: center;
  .img {
    background-color: red;
    width: 140px;
    height: 50px;
  }
  .hide {
    ul {
      align-items: center;
    }
  }
  @media (max-width: 1127px) {
    .hide {
    }
  }
  @media (max-width: 951px) {
    .hide {
      li {
        padding: 1rem 0.6rem 0 0.6rem;
      }
    }
  }
  @media (max-width: 884px) {
    .hide {
      li {
        padding: 1rem 0.4rem 0 0.4rem;
        font-size: 11px;
      }
    }
    img {
      width: 50%;
    }
  }
  @media (max-width: 689px) {
    .hide {
      li {
        padding: 1rem 0.2rem 0 0.2rem;
        font-size: 8px;
      }
    }
    img {
      width: 50%;
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    align-items: center;
    justify-content: space-around;
    li {
      padding: 5px 0;
      padding-right: 1rem;
      color: #ffffff;
      font-weight: 500;
      img {
        width: 100%;
        padding-top: 14px;
      }
      transition: 0.5s all;
      &:hover {
        color: #007ff3;
      }
    }
  }
`;
export const StyledLink = styled(HashLink)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  &:hover {
    /* color: #377ceb; */
  }
`;
