import styled from "styled-components";

export const Bigsection = styled.div`
  .headerContainer {
    position: relative;
    margin: 0 3rem;
    padding-top: 5rem;
    h1 {
      /* padding-left: 3.5rem; */
      /* color: var(--grey-darkest, #321433); */
      color: white;
      font-family: Open Sans;
      font-size: 4rem;
      font-style: normal;
      font-weight: 800;
      line-height: 140%; /* 5.6rem */
      letter-spacing: -0.01875rem;
    }
    svg {
      position: absolute;
      left: 0;
      top: 4.4rem;
    }
    p {
      padding-top: 1rem;
      color: #000;
      font-family: Open Sans;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 1.875rem */
      letter-spacing: -0.01875rem;
      max-width: 50%;
    }
    .back {
      display: flex;
      align-items: center;
    }
  }
`;
