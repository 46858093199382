import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AboutSmarty from "../components/AboutSmarty";
import FAQ from "../components/FAQ";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ScrollButton from "../components/Layout/ScrollButton";
import LottieComponent from "../components/LottieComponent";
import NavBar from "../components/NavBar";
import OurServices from "../components/OurServices";
import Reviews from "../components/Reviews";
import TalkWithUs from "../components/TalkWithUs";
import WhySmarty from "../components/WhySmarty";
import { GetAllPages } from "../Redux/Slices/PagesSlice/PageSlice";
import { GetAllServices } from "../Redux/Slices/ServicesSlice/ServicesSlice";
import Loader from "../components/Loader";
import MyMaps from "../components/GoogleMaps/MyMaps";
import NewNavBar from "../components/NewNavBar";
const HomePage = () => {
  const dispatch = useDispatch();
  const { servicesStatus } = useSelector((state) => state?.services);
  const { pagesStatus } = useSelector((state) => state?.pages);
  React.useEffect(() => {
    let apis = async () => {
      dispatch(GetAllServices());
      dispatch(GetAllPages());
    };
    apis();
  }, []);
  // React.useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://messenger.ebiai.app/static/js/main.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  //   window?.chatBot?.init({
  //     client: "63e2558b98c1f7001210754e",
  //     pipeline: "63e2558c697232001274ee47",
  //   });
  //   console.log(" window?.chatBot window?.chatBot", window?.chatBot);
  // }, []);

  return (
    <div>
      {servicesStatus === "loading" || pagesStatus === "loading" ? (
        <>
          <Loader></Loader>
        </>
      ) : (
        <>
          {" "}
          {/* <NavBar></NavBar> */}
          <NewNavBar></NewNavBar>
          <Header></Header>
          <AboutSmarty></AboutSmarty>
          <WhySmarty></WhySmarty>
          <OurServices></OurServices>
          <FAQ></FAQ>
          <TalkWithUs></TalkWithUs>
          <Footer></Footer>
          <ScrollButton></ScrollButton>
          <div id="chat_content"></div>
        </>
      )}
    </div>
  );
};

export default HomePage;
