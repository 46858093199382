import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import PeopleIcon from "@mui/icons-material/People";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SettingsIcon from "@mui/icons-material/Settings";
const ThreeDivs = () => {
  return (
    <Bigsetion>
      <div className="contnet">
        <Grid container columns={12} spacing={10}>
          <Grid item xs={12} md={6} lg={4}>
            <div className="singlediv">
              <h3>
                <PeopleIcon></PeopleIcon> WE Are
              </h3>
              <p>
                We are a group of achievers, doers, and masterminds who came
                together to fulfill your every marketing need. Our full-fledged
                digital company believes in empowering our clients with the best
                digital solutions out there so that they can have more time to
                spend on what really matters- making money. We serve communities
                we understand, communities we are a part of, that’s why our
                innovative digital assets speak directly to our clients and
                their clients. Operating in 10 countries in the GCC and MENA
                region, we reach clients in whatever part of the world they may
                be. At Victory Link we deliver data-driven solutions to cater to
                your every business need. Our in-depth knowledge of the market
                helps us foster new technologies and methods to efficiently
                provide you with digital marketing solutions that keep up and
                drive innovation within your business.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <div className="singlediv">
              <h3>
                <EmojiEventsIcon></EmojiEventsIcon> WHY US
              </h3>
              <p>
                We serve communities we understand, communities we are a part
                of, that’s why our innovative digital assets speak directly to
                our clients and their clients. Operating in 10 countries in the
                GCC and MENA region, we reach clients in whatever part of the
                world they may be.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <div className="singlediv">
              <h3>
                <SettingsIcon></SettingsIcon> WHAT WE DO
              </h3>
              <p>
                At Victory Link we deliver data-driven solutions to cater to
                your every business need. Our in-depth knowledge of the market
                helps us foster new technologies and methods to efficiently
                provide you with digital marketing solutions that keep up and
                drive innovation within your business.
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    </Bigsetion>
  );
};

const Bigsetion = styled.div`
  background-color: #121212;
  .contnet {
    width: 70%;
    margin: auto;
  }

  padding: 2rem 0;
  color: #ffffff;
  .singlediv {
    h3 {
      padding: 1rem 0;
      display: flex;
      svg {
        color: #e9d9a5;
        margin-right: 0.5rem;
      }
    }
    p {
      text-align: left;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      padding: 1.5rem 0;
    }
  }
`;
export default ThreeDivs;
