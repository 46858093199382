import React from "react";
import NewSignleBlog from "../newSingleBlog";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { Bigsection } from "./style";

const SingleBlog = ({ allBlogs }) => {
  console.log("allBlogs", allBlogs);

  return (
    <Bigsection>
      <div className="blogContanier">
        <Grid
          container
          columns={12}
          flexShrink={0}
          alignItems={"stretch"}
          spacing={5}
        >
          {allBlogs?.map((blog) => {
            return (
              <Grid item xs={12} md={6} lg={4} key={blog?.id}>
                <Link to={`/blogs/singleBlog/${blog?.id}`}>
                  <NewSignleBlog data={blog} />
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Bigsection>
  );
};

export default SingleBlog;
