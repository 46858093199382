import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import logo from "../assets/rsz_logo-f.webp";
import { StyledLink } from "../style/NavBarStyle";
import { Link } from "react-router-dom";
const NewNavBar = () => {
  return (
    <Grid container columns={12}>
      <Grid item xs={12}>
        <Nav>
          <nav class="navbar">
            <div class="navbar-container container">
              <input type="checkbox" name="" id="" />
              <div class="hamburger-lines">
                <span class="line line1"></span>
                <span class="line line2"></span>
                <span class="line line3"></span>
              </div>
              <ul className="menu-items">
                <StyledLink to="#about" smooth>
                  {" "}
                  <li>About</li>
                </StyledLink>
                <StyledLink to="#why" smooth>
                  {" "}
                  <li>Why Smarty</li>
                </StyledLink>
                <StyledLink to="#services" smooth>
                  {" "}
                  <li>Services</li>
                </StyledLink>
                {/* <StyledLink to="/succes-stories" smooth>
                {" "}
                <li>Success Stories</li>
              </StyledLink> */}
                {/* <StyledLink to="#reviews" smooth>
                {" "}
                <li>What People Say</li>
              </StyledLink> */}
                <StyledLink to="#questions" smooth>
                  {" "}
                  <li>FAQ</li>
                </StyledLink>

                <StyledLink to="#contact-us" smooth>
                  {" "}
                  <li>Contact us</li>
                </StyledLink>
                <Link to="/blogs">
                  <li>Blogs</li>
                </Link>
              </ul>
              <Link to="/">
                <img src={logo} alt="" className="logo" />
              </Link>
            </div>
          </nav>
        </Nav>
      </Grid>
    </Grid>
  );
};
const Nav = styled.nav`
  background-color: black;
  width: 100%;

  /* ............/navbar/............ *

/* desktop mode............/// */

  .navbar input[type="checkbox"],
  .navbar .hamburger-lines {
    display: none;
  }

  .navbar {
    position: fixed;
    padding: 0 2rem;
    backdrop-filter: blur(5px);
    width: 100%;
    color: white;
    height: 50px;
    z-index: 12;
  }

  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 64px;
    align-items: center;
  }

  .menu-items {
    order: 2;
    display: flex;
  }

  .menu-items li {
    list-style: none;
    margin-left: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
  }

  .menu-items a {
    text-decoration: none;
    color: white;
    font-weight: 500;
    transition: color 0.3s ease-in-out;
  }

  .menu-items a:hover {
    color: #0164c4;
    transition: color 0.3s ease-in-out;
  }

  .logo {
    order: 1;
    font-size: 2.3rem;
    margin-bottom: 0.5rem;
  }
  @media (max-width: 768px) {
    height: 3rem;
    .navbar {
      opacity: 0.95;
    }

    .navbar-container input[type="checkbox"],
    .navbar-container .hamburger-lines {
      display: block;
    }

    .navbar-container {
      display: block;
      position: relative;
      height: 64px;
    }

    .navbar-container input[type="checkbox"] {
      position: absolute;
      display: block;
      height: 32px;
      width: 30px;
      top: 20px;
      left: 20px;
      z-index: 5;
      opacity: 0;
    }

    .navbar-container .hamburger-lines {
      display: block;
      height: 23px;
      width: 35px;
      position: absolute;
      top: 17px;
      left: 20px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .navbar-container .hamburger-lines .line {
      display: block;
      height: 4px;
      width: 100%;
      border-radius: 10px;
      background: white;
    }

    .navbar-container .hamburger-lines .line1 {
      transform-origin: 0% 0%;
      transition: transform 0.4s ease-in-out;
    }

    .navbar-container .hamburger-lines .line2 {
      transition: transform 0.2s ease-in-out;
    }

    .navbar-container .hamburger-lines .line3 {
      transform-origin: 0% 100%;
      transition: transform 0.4s ease-in-out;
    }

    .navbar .menu-items {
      padding-top: 100px;
      background: black;
      height: 100vh;
      max-width: 300px;
      transform: translate(-150%);
      display: flex;
      flex-direction: column;
      margin-left: -40px;
      padding-left: 50px;
      transition: transform 0.5s ease-in-out;
      /* box-shadow: 5px 0px 10px 0px #aaa; */
      backdrop-filter: blur(15px);
      color: black;
    }

    .navbar .menu-items li {
      margin-bottom: 1.5rem;
      font-size: 1.3rem;
      font-weight: 500;
      color: white;
    }

    .logo {
      position: absolute;
      top: 5px;
      right: 15px;
      font-size: 2rem;
    }

    .navbar-container input[type="checkbox"]:checked ~ .menu-items {
      transform: translateX(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
      transform: rotate(35deg);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
      transform: scaleY(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
      transform: rotate(-35deg);
    }
  }
`;

export default NewNavBar;
