import React from "react";
import styled from "styled-components";
import ReadMore from "../ReadMore";

const CibCard = ({ card }) => {
  return (
    <BigSection>
      <div>
        {" "}
        <img src={card.icon}></img>
      </div>
      <div>
        {" "}
        <h4>{card.header}</h4>
      </div>
      <div>
        {" "}
        <p>
          {" "}
          <ReadMore>{card.description}</ReadMore>
        </p>
      </div>
      {/* <div className="btnContainer">
        {" "}
        <button className="btn">LearnMore</button>
      </div> */}

      {/* </div> */}
    </BigSection>
  );
};
const BigSection = styled.div`
  border: 2px solid hsla(0, 0%, 100%, 0.5);
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 20%);
  padding: 30px 20px;
  text-align: center;
  color: #fff;
  fill: #fff;
  position: relative;
  transition: all 0.3s;
  min-height: 350.6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 1rem 1.2rem;
  p {
    /* max-width: 190px; */
    /* margin-left: auto; */
    /* margin-right: auto; */
  }
  .btn {
    position: relative;
    min-width: 112px;
    min-height: 47px;
    z-index: 1;
    color: #fff;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    padding: 14px 22px;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    border-radius: 6px;
    border: 2px solid transparent;
    transition: all 0.35s ease 0s;
    background-color: #1858a8;
    font-size: 14px;
    /* font-size: 1rem; */
    line-height: 0.875;
  }
  .btnContainer {
    visibility: hidden;
  }
  &:before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: 0;
    background-color: #f68633;
    transition: all 0.3s;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  &:hover {
    color: #1858a8;
    background-color: #fff;
    transform: scale(1.1);
    transform-origin: bottom left;
    &:before {
      height: 3px;
    }
    .btnContainer {
      visibility: visible;
    }
  }
  img {
    width: 60px;
    height: 60px;
  }
`;

export default CibCard;
