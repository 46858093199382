import { Button, Grid } from "@mui/material";
import React from "react";
import { FAQStyle } from "../style/FaqStyle";
// import AddCircleOutlineIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Link } from "react-router-dom";
import { Slide } from "react-awesome-reveal";
import FAQS from "./FAQS";
import { questions } from "../Static arrays/AskedQuestions";
import { Margin } from "@mui/icons-material";

const FAQ = () => {
  const [open, setOpen] = React.useState(false);
  const [next, setNext] = React.useState(4);
  const handleMoreImage = () => {
    setNext(next + 3);
  };
  return (
    <FAQStyle id="questions">
      <Grid container columns={12}>
        <Grid item xs={12}>
          <div className="containerOne">
            <p className="header">Frequently asked questions</p>
            {/* <p>Everything you need to know about the product and billing.</p> */}
          </div>
        </Grid>
        <div className="container">
          {questions?.slice(0, next)?.map((e, i) => {
            return (
              <Grid item xs={12} key={i}>
                <FAQS question={e.question} answer={e.answer}></FAQS>
              </Grid>
            );
          })}
          {next < questions?.length && (
            <Button
              sx={{
                color: "white",
                backgroundColor: "#0464c4",
                margin: "10px 0",
              }}
              className="mt-4"
              onClick={handleMoreImage}
            >
              Load more
            </Button>
          )}
        </div>
      </Grid>
    </FAQStyle>
  );
};

export default FAQ;
