import styled from "styled-components";
import bg from "../assets/whySection.webp";
export const WhyStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  background-image: url(${bg});
  background-position: top right;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
  overflow-x: hidden;
  div:nth-child(1) {
    text-align: center;
    .header {
      background: transparent linear-gradient(100deg, #ffffff 0%, #377ceb 100%)
        0% 0% no-repeat padding-box;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 60px;
      font-weight: bold;
    }
  }
  .lorem {
    padding-top: 20px;
    color: white;
    max-width: 60%;
    margin: auto;
  }
  .singleDiv {
    color: white;
    .install {
      background: transparent linear-gradient(100deg, #ffffff 0%, #377ceb 100%)
        0% 0% no-repeat padding-box;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 16px;
      /* font-weight: bold; */
      padding: 1rem 1rem;
    }
    .lorem2 {
      width: 100%;
      line-height: 33px;
    }
    img {
      width: 64px;
      height: 64px;
    }
  }
  .learn {
    color: #ffffff;
    border: 1px solid #1386f4;
    background-color: #037ef3;
    padding: 0.5rem 4rem;
    border-radius: 48px;
    font-size: 24px;
    cursor: pointer;
  }
  .sliderContainer {
    display: none;
  }
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 760px) {
    .sliderContainer {
      display: block;
    }
    .normalSlider {
      display: none;
    }
  }
`;
