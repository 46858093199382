import React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Button } from "@mui/material";
import styled from "styled-components";
const ScrollButton = () => {
  const [visible, setVisible] = React.useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  window.addEventListener("scroll", toggleVisible);

  return (
    <Bigsection>
      <Content />
      <Button
        onClick={scrollToTop}
        endIcon={<ArrowUpwardIcon></ArrowUpwardIcon>}
        style={{ display: visible ? "block" : "none" }}
      >
        Go up
      </Button>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  button {
    position: fixed;
    right: 10px;
    bottom: 10px;
    color: white;
    font-size: 18px;
    svg {
      color: white;
      font-size: 25px;
    }
  }
`;
const Content = styled.div`
  overflowy: scroll;
  /* height: 2500px; */
`;
export default ScrollButton;
