import React, { useLayoutEffect, useState } from "react";
import BlogHeader from "./components/header";
import SingleBlog from "./components/singleBlog";
import axios from "axios";
import Loader from "../../components/Loader";
import { Bigsection } from "./style";
import NewNavBar from "../../components/NewNavBar";
const Blogs = () => {
  const [allBlogs, setAllBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async () => {
    setIsLoading(true);
    axios
      .get("https://octarback.adgrouptech.com/api/blogs")
      .then(function (response) {
        // handle success
        setIsLoading(false);
        setAllBlogs(response?.data?.data);
        console.log("response?.data?.data", response?.data?.data?.data);
      })
      .catch(function (error) {
        setIsLoading(false);

        // handle error
        console.log(error);
      });
  };
  console.log("allBlogs", allBlogs?.data);
  useLayoutEffect(() => {
    fetchData();
  }, []);
  if (isLoading) {
    return <Loader />;
  }
  return (
    <Bigsection>
      <div className="blogContainer">
        <NewNavBar />
        <BlogHeader />
        {/* <BlogNav /> */}
        <SingleBlog allBlogs={allBlogs?.data} />
      </div>
    </Bigsection>
  );
};

export default Blogs;
