import styled from "styled-components";

export const BigSection = styled.section`
  .singleBlogDataContainer {
    .content {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      .date {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        img {
          width: 100%;
          height: 100%;
          // height: 300px;
          object-fit: cover;
        }
        h1 {
          color: #000;
          color: white;
          /* Body */
          font-family: Open Sans;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: 180%; /* 2.025rem */
        }
        h2 {
          /* color: var(--grey-darkest, #321433); */
          font-family: Times New Roman;
          font-size: 2rem;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 2.8rem */
          letter-spacing: -0.01875rem;
          color: white;
        }
      }
      .divide {
        // color: red;
      }
      // div:nth-child(3) {
      //   color: var(--primary-dark, #9e32a4);
      //   font-family: Open Sans;
      //   font-size: 1.5rem;
      //   font-style: normal;
      //   font-weight: 700;
      //   line-height: 150%; /* 2.25rem */
      //   letter-spacing: -0.01875rem;
      // }
      .singleParagraph {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        margin: 1rem 0;
        p:first-child {
          color: var(--primary-dark, #9e32a4);

          /* Label/Bold */
          font-family: Open Sans;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 1.225rem */
          letter-spacing: -0.01875rem;
          text-transform: uppercase;
        }
        .photo {
          display: flex;
          align-items: center;
          gap: 0.75rem;

          div {
            width: 3rem;
            height: 3rem;
            border-radius: 2.5rem;
            background: #d9d9d9;
          }
        }
      }
    }
    ul {
      list-style: none;
      li {
        padding: 0.5rem 0;
      }
    }
  }
`;
