import React from "react";
import styled from "styled-components";

const Aboutsection = () => {
  return (
    <Bigsection>
      <h3 className="header">About Smarty</h3>
      <p className="lorem">
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque placeat,
        eius dicta nam praesentium ut distinctio obcaecati consequuntur eaque
        possimus incidunt doloribus quia modi veritatis doloremque, error
        aliquam. Quibusdam, minima.
      </p>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  text-align: center;
  background-color: black;
  color: white;
  padding: 5rem 0;
  .header {
    background: transparent linear-gradient(100deg, #ffffff 0%, #377ceb 100%) 0%
      0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 60px;
    font-weight: bold;
    
  }
  .lorem {
    width: 55%;
    margin: auto;
    line-height: 38px;
  }
`;
export default Aboutsection;
